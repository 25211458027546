.news101-list {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 5vw;

	@include breakpoint(sm) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 2.5vw;
	}

	@include breakpoint(md) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.news101-list__load-more {
	display: block;
	margin-top: 2em;
	text-align: center;
	.linkbutton{
		background-color: transparent;
		border: none;
		cursor: pointer;
		&:focus{

			outline: none;
			border: 2px solid color(red500);
		}
	}
}

.news101-list__load-more--hidden {
	display: none;
}
