/*
	DB.Neos.Form / Main.scss

	Diese Datei enthält alle Styles, die für die im Paket enthaltenen
	Module benötigt werden. Hier können Variablen und Vererbungen
	aus dem DB.Neos.Base Paket verwendet werden.

	Projektspezifische Änderungen an den Komponenten dieses Paketes
	finden im Projekt-Paket (z.B. DB.Hochwasser) statt.
*/

//*******************************************************************************
// FORMS
//*******************************************************************************

//
// Wrapper for label and input
//
.form__group {
	margin-bottom: 20px;

	.invalid-feedback {
		font-weight: 400;
		color: color(errorred);
		font-size: 1rem;
		line-height: 30px;
	}

	a {
		color: color(black);
		text-decoration: underline;

		&:hover {
			color: color(red600);
		}
	}
}



//
// Fieldset
//
fieldset {
	padding: 0;
	border: none;
}


//
// Label
//
label {
	@include bodytextsize;
	.required{
		margin-left: 2px;
	}
}



//
// Input
//
.form__control {
	@include placeholder{ color: color(coolgray500); opacity: 1; }
	display: block;
	width: 100%;
	height: 40px;
	padding: 5px 20px;
	border: 1px solid transparent;
	border-radius: $border-radius;
	background-color: color(white);
	color: color(black);
	box-shadow: $box-shadow--1;
	font-size: $textfontsizemin + px;
	line-height: $textlineheightmin + px;

	@include breakpoint(mid) {
		@include bodytextsize;
		height: 56px;
		padding: 20px 15px;
	}

	@include breakpoint(max) {
		height: 72px;
	}

	&:focus {
		outline: none;
		border: 1px solid color(coolgray300);
	}
	&:focus-visible{
		outline: none;
		box-shadow: 0 0 0 2px color(red500);
	}

	&.is-invalid {
		border: 1px solid color(errorred);

		&:focus {
			border: 1px solid color(coolgray300);
		}
	}

	&:-internal-autofill-selected {
		border: 1px solid color(coolgray300);
	}
}



//
// Dropdown
//
.form__control--select {
	appearance: none;
	position: relative;
	background-size: 20px;
	background-position: right 20px center;
	background-repeat: no-repeat;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHBvbHlsaW5lIHRyYW5zZm9ybT0icm90YXRlKDkwLDYsNikiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzRENEQ2MCIgcG9pbnRzPSIzLjcgMS44IDcuOSA2IDMuNyAxMC4yIi8+PC9zdmc+);
	cursor: pointer;

	@include breakpoint(mid) {
		height: 64px;
	}
}



//
// Textarea
//
.form__textarea {
	display: block;
	min-height: 200px;
	padding: .5vw;
	margin-bottom: 2.5vw;
}



//
// Checkbox
//
.form__checkbox {
	$checkboxsize: 28px;

	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	position: relative;

	.form__checkbox-input {
		position: absolute;
		left: -40px;
		top: 0;
		width: 40px;
		height: 40px;
		margin: 0;
		padding: 0;
		line-height: 1;
		opacity: 0;

		&:checked+label::before {
			// border: 1px solid color(coolgray300);
			@include background-svg('<svg width="22" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M0 9.032c0-.58.484-1 1-1 .387 0 .484.065.71.29L7 13.582 20.258.29a.925.925 0 01.71-.291C21.548 0 22 .516 22 1c0 .258-.097.484-.323.71l-13.967 14c-.194.193-.452.29-.71.29a.925.925 0 01-.71-.29l-6-5.968a1 1 0 01-.29-.71z" fill="#000" fill-rule="nonzero"/></svg>');
			font-size: 1.2rem;
		}

		&:focus+label::before {
			border-color: color(black);
		}

		&:focus:not(.focus-visible)+label::before {
			border-color: transparent;
		}
		&:focus-visible+label::before {
			outline: none;
			border-color: transparent;
			box-shadow: 0 0 0 2px color(red500);
		}
	}

	.form__checkbox-label {
		position: relative;
		padding-left: 40px;
		cursor: pointer;

		&::before {
			content: '';
			display: inline-block;
			width: $checkboxsize;
			height: $checkboxsize;
			margin-right: 10px;
			position: absolute;
			left: 0;
			top: 0;
			background-size: 18px;
			background-repeat: no-repeat;
			background-position: center center;
			background-color: color(bright);
			border: 1px solid transparent;
			border-radius: $border-radius;
			box-shadow: $box-shadow--1;
			font-size: $checkboxsize;
			font-weight: 600;
			line-height: $checkboxsize;
			color: #000;
			text-align: center;
		}
	}
}



//
// File-Upload
//
.form__upload {
	margin-bottom: 1rem;

	.form__upload-input-wrapper {
		position: relative;
		line-height: 1;
	}

	.form__upload-input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 0;
		height: 0;
		cursor: pointer;

		&:focus {
			outline: none;
			border: 1px solid color(black);
		}
	}

	.form__upload-input:focus + .form__upload-label {
		color: #fff;
		// background-color: color(black);
		border-color: color(black);
	}
}



//
// Radio Buttons
//
.form__radio {
	$radiodiameter: 28px;

	.form__radio-input-wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.form__radio-label {
		flex-basis: 100%;
		margin: 0 0 9px 0;
		cursor: pointer;
	}

	.form__radio-input {
		position: absolute;
		overflow: hidden;
		height: 1px;
		width: 1px;
		margin: -1px;
		padding: 0;
		border: 0;
		clip: rect(0 0 0 0);
	}

	.form__radio-label-text {
		display: flex;
		align-items: center;
		position: relative;
		min-height: $radiodiameter;

		&::before {
			content: '';
			display: block;
			width: $radiodiameter;
			height: $radiodiameter;
			border-radius: 50%;
			border: 1px solid transparent;
			box-shadow: $box-shadow--1;
			background-color: color(bright);
			margin-right: calc($radiodiameter / 2);
			transition: 0.5s ease all;
		}

		i {
			width: auto;
			line-height: 1.4;
			font-style: normal;
		}

		&::after {
			content: '';
			position: absolute;
			top: calc($radiodiameter / 4);
			left: calc($radiodiameter / 4);
			display: none;
			width: $radiodiameter * 0.5;
			height: $radiodiameter * 0.5;
			border-radius: 50%;
			background-color: color(black);
		}
	}

	// Checked Radio
	.form__radio-input:checked + .form__radio-label-text::after {
		display: block;
	}

	// Focus
	.form__radio-input:focus + .form__radio-label-text::before {
		border-color: color(coolgray300);
	}
}



//
// Form Submit
//
.form__submit {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;

	& > .btn.focus-visible {
		outline: 2px solid #9b0000;
		outline-offset: 2px;
		box-shadow: none;
	}
}

