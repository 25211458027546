%hoverStyles {
	background: color(white);
	box-shadow: 0 -2px 6px rgba(0,0,0,0.06);

	@include breakpoint(mid) {
		height: 79px;
		margin-bottom: -1px;
		padding-bottom: 1px;
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
	}

	@include breakpoint(max) {
		height: 4.6vw;
	}

	&:after {
		content: '';
		position: absolute;
		height: 4px;
		bottom: 5px;
		left: 50%;
		transform: translateX(-50%);
		border-radius: $border-radius;
		background: color(red500);
		width: 30%;
		max-width: 72px;
	}
}

.tabcontainer {
	text-align: center;

	@include breakpoint(mid) {
		text-align: center;
		padding-left: 5vw;
		padding-right: 5vw;
		border-bottom: 1px solid color(coolgray200);
	}

	.list {
		margin: 0;
	}

	&__navlist {
		display: flex;
		margin-left: -.5vw;
		margin-right: -.5vw;
		margin-bottom: 0;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;

		@include breakpoint(mid) {
			flex-wrap: nowrap;
		}

		li {
			display: inline-flex;
			height: 79px;
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: 30%;
			flex-direction: column;
			justify-content: flex-end;

			@include breakpoint(mid) {
				flex-grow: 1;
				flex-shrink: 1;
				flex-basis: initial;
				max-width: 15vw;

				&:not(:last-child) {
					margin-right: 0;
				}

				&:first-child {
					.tabcontainer__navlink-wrapper {
						border-top-left-radius: $border-radius;
					}
				}

				&:last-child {
					.tabcontainer__navlink-wrapper {
						border-top-right-radius: $border-radius;
					}
				}
			}

			@include breakpoint(max) {
				height: 4.6vw;
			}

			&.is-active {
				.tabcontainer__navlink-wrapper {
					@extend %hoverStyles;
				}
			}
		}
	}

	&__navlink-wrapper {
		position: relative;
		background: color(coolgray100);
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		border-radius: $border-radius;

		@include breakpoint(mid) {
			border-radius: 0;
		}

		&:hover,
		&:focus,
		.focus-visible{
			@extend %hoverStyles;
		}
	}

	&__navlink {
		display: grid;
		height: 72px;
		padding-left: 1vw;
		padding-right: 1vw;
		@include bodytextsize();
		color: color(black);
		line-height: 1.2 !important;

		@include breakpoint(mid) {
		}

		@include breakpoint(max) {
			height: 4.2vw;
		}

		&:hover,
		&:focus {
			color: color(black);
		}

		span {
			place-self: center;
		}
	}
}
