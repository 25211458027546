//*******************************************************************************
// Teaser - with Videoplayer
//*******************************************************************************


//
// Default
//
.teaser--video {
	@include breakpoint(mid) {
		margin: 0 5vw;
		display: grid;
		grid-template-columns: 45vw 45vw;
		grid-template-rows: 18vw 2vw auto auto 16vw;
	}

	@include breakpoint(max) {
		grid-template-columns: 50vw 40vw;
	}

	.teaser__title,
	.teaser__content,
	.teaser__buttons {
		text-align: left;

		@include breakpoint(mid) {
			grid-column: 2 / span 1;
		}
	}

	.teaser__title {
		margin-bottom: 0;

		@include breakpoint(mid) {
			grid-row: 1 / span 2;
			align-self: end;
			margin-top: 2vw;
			margin-bottom: 2vw;
			font-size: calc(36px + (64 - 36) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
			line-height: calc(40px + (72 - 40) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		}

		@include breakpoint(max) {
			font-size: 4vw;
			line-height: 4.5vw;
		}
	}

	.teaser__content {
		margin: 0;

		@include breakpoint(mid) {
			grid-row: 3 / span 1;
		}
	}

	.teaser__videowrapper {
		position: relative;
		padding: 8vw 0;
		margin: 8vw 0;

		@include breakpoint(mid) {
			grid-column: 1 / span 1;
			grid-row: 1 / span 5;
			margin: 0;
			padding: 12vw 0 16vw 0;
		}

		@include breakpoint(max) {
			padding: 8vw 0 12vw 0;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 15vw;
			right: -5vw;
			z-index: -1;
			display: block;
			border-top-left-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
			background-color: color(coolgray100);
			width: auto;
			height: 100%;

			@include breakpoint(mid) {
				left: 0;
				width: 35vw;
				border-radius: $border-radius;
			}
		}
	}

	.teaser__videowrapperbox {
		@include breakpoint(mid) {
			width: 100%;
			width: calc(100% - 8vw);
			margin-left: 4vw;
			margin-right: 4vw;
		}
	}

	.teaser__videowrapperinner {
		position: relative;
		padding-top: 56.15%;
		border-radius: $border-radius;
		box-shadow: $box-shadow--3;
		background-color: color(bright);
		overflow: hidden;
	}

	.teaser__media {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		object-fit: contain;

		// &:focus {
		// 	box-shadow: 0 0 0 2px yellow;
		// }

		&:hover {
			cursor: pointer;

			// + .teaser__playvideo {
			// 	opacity: 1;
			// 	visibility: visible;
			// }
		}

		&.is--hidden {
			opacity: 0;
			visibility: hidden;
		}

		// &.focus-visible {
		// 	+ .teaser__playvideo {
		// 		opacity: 1;
		// 		visibility: visible;
		// 	}
		// }
	}

	.teaser__playvideo {
		// opacity: 0;
		// visibility: hidden;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: all $transition-time $transition-function;
		transform-origin: center;

		// &:focus {
		// 	opacity: 1;
		// }

		// &:hover {
		// 	opacity: 1;
		// }

		// &.is--hidden {
		// 	opacity: 0;
		// }
	}

	.teaser__buttons {
		@include breakpoint(mid) {
			grid-row: 4 / span 1;
		}
	}
}



//
// Reversed Version (Text left; Video right)
//
.teaser--video-reversed {

	@include breakpoint(max) {
		grid-template-columns: 40vw 50vw;
	}

	.teaser__title,
	.teaser__content,
	.teaser__buttons {
		@include breakpoint(mid) {
			grid-column: 1 / span 1;
		}
	}

	.teaser__videowrapper {
		@include breakpoint(mid) {
			grid-column: 2 / span 1;
		}
	}

	.teaser__videowrapper::after {
		right: 15vw;
		left: -5vw;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-top-right-radius: $border-radius;
		border-bottom-right-radius: $border-radius;

		@include breakpoint(mid) {
			left: auto;
			right: 0;
			width: 35vw;
			border-radius: $border-radius;
		}
	}
}




//
// Variant 2 - Bigger Backdrop behind text; text right.
//
.teaser--video-variant-2 {
	position: relative;
	grid-template-columns: 37vw 4vw 45vw 4vw;
	grid-template-rows: auto auto auto;

	@include breakpoint(max) {
		grid-template-columns: 42vw 4vw 40vw 4vw;
	}

	&::after {
		@include breakpoint(mid) {
			content: '';
			position: absolute;
			top: 0;
			left: auto;
			right: 0;
			z-index: -1;
			display: block;
			width: 70vw;
			width: calc(560px + (960 - 560) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
			height: 100%;
			background-color: color(coolgray100);
			border-radius: $border-radius;
		}

		@include breakpoint(max) {
			left: 30vw;
			width: 60vw;
		}
	}

	.teaser__title {
		@include breakpoint(mid) {
			grid-column: 3 / span 1;
			grid-row: 1 / span 1;
			margin-top: 4vw;
			margin-bottom: 4vw;
			align-self: start;
		}
	}

	.teaser__content {
		@include breakpoint(mid) {
			grid-column: 3 / span 1;
			grid-row: 2 / span 1;
			align-self: start;
		}
	}

	.teaser__videowrapper {
		@include breakpoint(mid) {
			align-self: center;
			padding: 0;
		}

		@include breakpoint(max) {
			padding: 4vw 0 10.5vw 0;
		}

		&::after {
			@include breakpoint(mid) {
				display: none;
			}
		}
	}

	.teaser__videowrapperbox {
		@include breakpoint(mid) {
			margin: 0;
			width: 100%;
		}
	}

	.teaser__buttons {
		grid-row: 3 / span 1;
	}
}



//
// Variant 2 Reversed - Bigger Backdrop behind text; text left.
//
.teaser--video-variant-2-reversed {
	grid-template-columns: 4vw 45vw 4vw 37vw;

	@include breakpoint(max) {
		grid-template-columns: 4vw 40vw 4vw 42vw;
	}

	&::after {
		@include breakpoint(mid) {
			left: 0;
		}
	}

	.teaser__title,
	.teaser__content,
	.teaser__buttons {
		@include breakpoint(mid) {
			grid-column: 2 / span 1;
		}
	}

	.teaser__videowrapper {
		@include breakpoint(mid) {
			grid-column: 4 / span 1;
		}
	}

	.teaser__videowrapper {
		&::after {
			left: -5vw;
			right: 15vw;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-top-right-radius: $border-radius;
			border-bottom-right-radius: $border-radius;

			@include breakpoint(mid) {
				display: none;
			}
		}
	}
}




//
// NEW
//
.teaser--video-fullbleed {

	@include breakpoint(mid) {
		margin: 0 5vw;
		display: grid;
		grid-template-columns: 45vw 45vw;
		grid-template-rows: 12vw auto auto 8vw;
	}

	@include breakpoint(max) {
		grid-template-columns: 50vw 40vw;
	}

	.teaser__title {
		@include breakpoint(mid) {
			grid-row: 2 / span 1;
			align-self: end;
			// margin-top: 0;
			margin-left: 5vw;
		}
		@include breakpoint(max) {
			margin-left: 0;
			margin-right: 10vw;
		}
	}

	.teaser__content {
		@include breakpoint(mid) {
			grid-row: 3 / span 1;
			margin-left: 5vw;
		}

		@include breakpoint(max) {
			margin-left: 0;
			margin-right: 10vw;
		}
	}





	.teaser__videowrapper {
		@include breakpoint(mid) {
			grid-row: 1 / span 4;
			padding: 12vw 0 8vw 0;
		}

		&::after {
			left: -5vw;
			right: 15vw;
			border-top-left-radius: 0;
			border-top-right-radius: $border-radius;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: $border-radius;

			@include breakpoint(mid) {
				left: -5vw;
				width: 45vw;
			}
		}
	}

	.teaser__videowrapperbox {
		@include breakpoint(mid) {
			width: 45vw;
			margin-left: 0;
			margin-right: 0;
		}
	}
}
