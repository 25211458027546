.fullwidthmap {

  .popup,
  .mapboxgl-popup,
  .maplibregl-popup
  {
    z-index:2 !important;
  }


  .mapboxgl-popup-content,
  .maplibregl-popup-content {
    padding: 0;
    border-radius: 7px;
    overflow: hidden;
    box-shadow: 3px 4px 8px 3px #0000001a;

    h4 {
      display: block;
      padding: 15px;
      margin: 0;
      font-size: 14px;
    }

    p {
      margin: 0;
      padding: 15px;
      padding-top: 0;
      font-size: 14px;
      line-height: 1.5;
    }
  }

  .image-container img {
    display: block;
    width: 100%;
  }
}
