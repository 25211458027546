// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

//
// Debugging
//
// Swap rows for easy enable / disable
//
$htmlLinting: true;
$htmlLinting: false;
$showPesticide: true;
$showPesticide: false;
$showGridDisplayer: true;
$showGridDisplayer: false;
$media-queries-debugging: true;
$media-queries-debugging: false;

//
// Colors
// Get one Value: .test { color: map-get($colors, orange); }
// or use the function: .test { color: color(black); }
//
$colors: (
  black: #000,
  white: #fff,
  dark: #000,
  bright: #fff,

  red600: #c50014,
  red500: #ec0016,
  errorred: #e3000f,

  // Orange and green is used for calendar
  orange500: #f39200,
  green500: #408335,

  blue500: #002b59,

  warmgray800: #38342f,
  warmgray700: #4f4b41,
  warmgray600: #747067,
  warmgray500: #858379,
  warmgray400: #9c9a8e,
  warmgray300: #bcbbb2,
  warmgray200: #ddded6,
  warmgray100: #f5f4f1,

  coolgray800: #131821,
  coolgray700: #282d37,
  coolgray600: #3c414b,
  coolgray500: #646973,
  coolgray400: #878c96,
  coolgray300: #afb4bb,
  coolgray200: #d7dce1,
  coolgray100: #f0f3f5,

  lightgreen400: #78be14,
  lightgreen500: #63a615,

  coolgray400Op50: rgb(195, 197.5, 202.5),
);

//
// Font-Sizes
//
$textminbreakpoint: 400;
$textmidbreakpoint: 800;

$textfontsizemin: 14;
$textlineheightmin: 24;
$textfontsizemax: 18;
$textlineheightmax: 32;

$textcompactfontsizemin: 14;
$textcompactlineheightmin: 20;
$textcompactfontsizemax: 16;
$textcompactlineheightmax: 24;

$textbiggerfontsizemin: 16;
$textbiggerlineheightmin: 26;
$textbiggerfontsizemid: 20;
$textbiggerlineheightmid: 26;
$textbiggerfontsizemax: 26;
$textbiggerlineheightmax: 34;

$h1fontsizemin: 36;
$h1lineheightmin: 40;
$h1fontsizemid: 45;
$h1lineheightmid: 47;
$h1fontsizemax: 64;
$h1lineheightmax: 72;

$h2fontsizemin: 26;
$h2lineheightmin: 30;
$h2fontsizemid: 32;
$h2lineheightmid: 36;
$h2fontsizemax: 48;
$h2lineheightmax: 62;

$h3fontsizemin: 20;
$h3lineheightmin: 26;
$h3fontsizemid: 20;
$h3lineheightmid: 32;
$h3fontsizemax: 20;
$h3lineheightmax: 32;

$textsmallfontsizemin: 10;
$textsmalllineheightmin: 12;
$textsmallfontsizemax: 14;
$textsmalllineheightmax: 24;

$transition-time: 0.2s;
$transition-function: ease-in-out;
$transition: all $transition-time $transition-function;

$box-shadow--1: 0 3px 4px 0 rgba(0, 0, 0, 0.05), 0 0 8px 0 rgba(0, 0, 0, 0.1),
  0 8px 16px 0 rgba(0, 0, 0, 0.15);
$box-shadow--2: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.1),
  0 3px 8px 0 rgba(0, 0, 0, 0.15);
$box-shadow--3: 0 3px 4px 0 rgba(0, 0, 0, 0.05), 0 0 8px 0 rgba(0, 0, 0, 0.1),
  0 8px 16px 0 rgba(0, 0, 0, 0.15);
$shadow-default: 0 0 8px 0 rgba(19, 24, 33, 0.15);
$shadow-default-hover: 0 0 20px 0 rgba(19, 24, 33, 0.15);
$shadow-default-2: 0 2px 9px 0 rgba(0, 0, 0, 0.2);

$border-radius: 6px;
$border-radius-small: 3px;

//
// Breakpoints
//
$smallestBreakpoint: xxs;
$breakpoint-xs: 414px; // iPhone Plus
$breakpoint-sm: 640px;
$breakpoint-sl: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1520px;

$breakpoint-min: 400px;
$breakpoint-mid: 800px;
$breakpoint-max: 1600px;

$breakpoint-mobile-navigation: 950px;

//
// Website globals
//
$max-width: none;
$z-index-1: 10;
$z-index-2: 20;
$z-index-3: 30;
$z-index-4: 40;
$z-index-5: 50;
$z-index-6: 60;
$z-index-7: 70;

//
// Spacer Value
//
$spacer: 1vw;

//
// Autoprefixer
// enable grid translations for IE
// Used as control comment
// https://github.com/postcss/autoprefixer#does-autoprefixer-polyfill-grid-layout-for-ie
// https://css-tricks.com/css-grid-in-ie-duplicate-area-names-now-supported/#article-header-id-11
//

/* Globally enable grid prefixes */
/* autoprefixer grid: on */
