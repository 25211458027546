.news101-s-h {
	width: 100%;
	display: grid;
	grid-template-columns: 25% 75%;

	&__slider-ui {
		grid-column: 1/3;
		grid-row: 3;
		margin: 24px 0;
	}

	&__slider-pagination {
		display: flex;
		justify-content: center;
	}

	&__textarea {
		grid-column: 1/3;
		grid-row: 1;
		display: grid;
		align-self: center;
		grid-template-columns: 1fr auto;
		grid-auto-rows: min-content;
		padding: 0 10vw;
		/* TODO::oder lieber 5vw  */

		@include breakpoint($breakpoint-max) {
			margin-bottom: 2.5vw;
		}

		h2 {
			grid-column: 1;
			grid-row: 1;
		}

		p {
			grid-column: 1/-1;
			grid-row: 2;
		}

		a {
			grid-column: 2;
			grid-row: 1;
			align-self: center;
		}
	}


	&__slider {
		width: 100%;
		grid-column: 1/3;
		grid-row: 2;
		overflow: visible;
	}

	@include breakpoint(mid) {
		grid-template-rows: repeat(2, auto);

		&__textarea {
			justify-content: center;
			grid-column: 1/2;
			grid-row: 2;
			padding: 0;
			padding-right: 2vw;
			margin-bottom: 0;


			h2 {
				grid-row: 1;
			}

			p {
				grid-row: 2;
			}

			a {
				grid-column: 1;
				grid-row: 3;
			}

			/* padding: 0 calc(5vw + 80 * (100vw - 800px) / 800); */
		}

		&__slider {
			grid-column: 2/3;
			overflow: hidden;
		}

		&__slider-ui {
			margin-right: calc(5vw + 80 * (100vw - 800px) / 800);
		}
	}


	&__swiper-wrapper {
		align-items: stretch;
	}

	&__slide {
		height: auto;
	}

	&__navigation {
		display: none;
		justify-content: center;

		&--visible {
			display: flex;
		}
	}

	&__nav-button {
		margin: 0;
		display: flex;
		align-items: center;
		background-color: color(red500);
		border: 0;
		border-radius: $border-radius;
		color: #fff;
		cursor: pointer;
		height: 48px;
		justify-content: center;
		transition: opacity .3s linear, background-color .3s ease;
		width: 48px;
		flex: 0 0 48px;

		&:hover,
		&:focus-visible,
		&:active {
			background-color: color(red600)
		}

		&.swiper-button-disabled {
			background-color: color(coolgray200);
			cursor: not-allowed;
		}

		svg {
			width: 12px;
			height: 22px;
		}

		&+& {
			margin-left: 8px;
		}
	}

	&__pagination {
		display: none;
		align-items: center;
		padding-left: 24px;

		&--visible {
			display: block;
		}
	}

	.swiper {
		/* height: fit-content; */
		padding: 1em;

		.swiper-wrapper {
			width: fit-content;
		}
	}
}
