//*******************************************************************************
// This file contains CSS helper classes.
//*******************************************************************************

//
// Clear Fix
//
.clearfix {
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}



//
// Clear both
//
.clear {
	clear: both;
}


//
// Floating Helpers
//
.float-left  { float: left!important; }
.float-right { float: right!important; }



//
// Display Layout
//
.block { display: block; }
.inline { display: inline; }
.inline-block { display: inline-block; }


//
// Flexbox Helper
//
.flexbox {
	display: flex;
}

.flexbox--sl {
	@include breakpoint(sl) {
		display: flex;
	}
}

.flexbox--md {
	@include breakpoint(md) {
		display: flex;
	}
}

.flexbox--lg {
	@include breakpoint(lg) {
		display: flex;
	}
}

.flex-shrink-0 {
	flex-shrink: 0;
}



//
// Width Helpers
//
.w-10 {
	width: 10vw;
}



//
// Box-Shadow Helper
//
.box-shadow--1 { box-shadow: $box-shadow--1; }
.box-shadow--2 { box-shadow: $box-shadow--2; }
.box-shadow--3 { box-shadow: $box-shadow--3; }



//
// Border-Radius
//
.border-radius {
	border-radius: $border-radius;
}



//
// Highlight Element
//
.mark {
	background-color: yellow;
	color: black;
}



//
// Font Family Helpers
//
// .font-regular { @include font-family(); }
// .font-light   { @include font-family(); }
// .font-bold    { @include font-family(); }



//
// Text Alignment classes
//
.text-left   { text-align: left; }
.text-right  { text-align: right; }
.text-center { text-align: center; }



//
// Text Transformation classes
//
.text-lowercase  { text-transform: lowercase; }
.text-uppercase  { text-transform: uppercase; }



//
// Text no-wrap
//
.text-nowrap {
	white-space: nowrap;
}


//
// Text word-break
//
.text-wordbreak {
	word-break: break-all;
}



//
// Text truncate
//
.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}



//
// two columns
//
.two-columns {
	@include breakpoint(md) {
		@include columns();
	}
}



//
// Position relative
//
.relative {
	position: relative;
}



//
// position:fixed page header and in-page anchors
// http://stackoverflow.com/a/38106970/808113
//
.anchor[id] {
	display: block;
	content: "";
	margin-top: -130px; // Set the Appropriate Height
	height: 130px; // Set the Appropriate Height
	visibility: hidden;
}



//
// Fixed Body Helper body.locked
// Disable Page Scrolling
//
body.locked {
	position: relative;
	height: 100%;
	overflow: hidden;
}



//
// SVG
//
svg {
	display: block;
	width: 100%;
	height: auto;
}



//
// Images
//
// Broken Images: https://bitsofco.de/styling-broken-images/
//
img {
	position: relative;
	display: block;
	max-width: 100%;
	height: auto;
	min-height: 40px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;

	&::before {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: center center no-repeat #f0f0f0;
		background-size: 40px;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjAiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDAwIDMwNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNDAwIDMwNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGcgb3BhY2l0eT0iMC4xNSI+PGRlZnM+PHJlY3QgaWQ9IlNWR0lEXzFfIiB4PSItNTYiIHk9Ii0xMDQiIG9wYWNpdHk9IjAuMTUiIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIi8+PC9kZWZzPjxwYXRoIGNsaXAtcGF0aD0idXJsKCNTVkdJRF8yXykiIGQ9Ik0xNiwyODhoMzY4VjE2SDE2VjI4OHogTTQwMCwzMDRIMFYwaDQwMFYzMDR6Ii8+PHBvbHlnb24gY2xpcC1wYXRoPSJ1cmwoI1NWR0lEXzRfKSIgcG9pbnRzPSIyNjQsMjQzLjMgMTY4LDE0Ny4zIDc3LjcsMjM3LjcgNjYuMywyMjYuMyAxNjgsMTI0LjcgMjY0LDIyMC43IDI5Ny4yLDE4Ny41IDMzMy44LDIyNi41IDMyMi4yLDIzNy41IDI5Ni44LDIxMC41Ii8+PHBhdGggZD0iTTI5Niw4MGMtMTMuMiwwLTI0LDEwLjgtMjQsMjRzMTAuOCwyNCwyNCwyNHMyNC0xMC44LDI0LTI0UzMwOS4yLDgwLDI5Niw4MHogTTI5NiwxNDRjLTIyLjEsMC00MC0xNy45LTQwLTQwIHMxNy45LTQwLDQwLTQwczQwLDE3LjksNDAsNDBTMzE4LjEsMTQ0LDI5NiwxNDR6Ii8+PC9nPjwvc3ZnPg==);
	}
}



//
// Lazyload Image
//
.lazyload,
.lazyloading {
	background: center center no-repeat #f0f0f0;
	background-size: 40px;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjAiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDAwIDMwNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNDAwIDMwNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGcgb3BhY2l0eT0iMC4xNSI+PGRlZnM+PHJlY3QgaWQ9IlNWR0lEXzFfIiB4PSItNTYiIHk9Ii0xMDQiIG9wYWNpdHk9IjAuMTUiIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIi8+PC9kZWZzPjxwYXRoIGNsaXAtcGF0aD0idXJsKCNTVkdJRF8yXykiIGQ9Ik0xNiwyODhoMzY4VjE2SDE2VjI4OHogTTQwMCwzMDRIMFYwaDQwMFYzMDR6Ii8+PHBvbHlnb24gY2xpcC1wYXRoPSJ1cmwoI1NWR0lEXzRfKSIgcG9pbnRzPSIyNjQsMjQzLjMgMTY4LDE0Ny4zIDc3LjcsMjM3LjcgNjYuMywyMjYuMyAxNjgsMTI0LjcgMjY0LDIyMC43IDI5Ny4yLDE4Ny41IDMzMy44LDIyNi41IDMyMi4yLDIzNy41IDI5Ni44LDIxMC41Ii8+PHBhdGggZD0iTTI5Niw4MGMtMTMuMiwwLTI0LDEwLjgtMjQsMjRzMTAuOCwyNCwyNCwyNHMyNC0xMC44LDI0LTI0UzMwOS4yLDgwLDI5Niw4MHogTTI5NiwxNDRjLTIyLjEsMC00MC0xNy45LTQwLTQwIHMxNy45LTQwLDQwLTQwczQwLDE3LjksNDAsNDBTMzE4LjEsMTQ0LDI5NiwxNDR6Ii8+PC9nPjwvc3ZnPg==);
}



//
// Responsive images
//
.img-fluid {
	max-width: 100%;
	height: auto;
}



//
// iFrame
//
iframe {
	border: 0;
}



//
// Hide text while making it readable for screen readers
// 1. Needed in WebKit-based browsers because of an implementation bug;
// See: https://code.google.com/p/chromium/issues/detail?id=457146
//
.hide-text {
	overflow: hidden;
	padding: 0; /* 1 */
	text-indent: 101%;
	white-space: nowrap;
	color: transparent;
	font: 0/0 a;
	text-shadow: none;
	background-color: transparent;
	border:0
}



//
// Hide element while making it readable for screen readers
// Shamelessly borrowed from HTML5Boilerplate:
// https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
//
.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}



//
// Hide only visually, but have it available for screen readers:
// https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
//
// 1. For long content, line feeds are not interpreted as spaces and small width
//    causes content to wrap 1 word per line:
//    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
//

.sr-only {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
	/* 1 */
}


//
// Only display content to screen readers
// See: http://a11yproject.com/posts/how-to-hide-content/
//
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}



//
// Lazy Loading
//
// .lazyload,
// .lazyloading {
//   background: url(../images/img-loading.svg) center center no-repeat #f0f0f0;
//   background-size: 50px;
//   min-height: 60px;
// }

// .lazyloading {
//   @include opacity(.8);
// }



//
// Showing & Hiding Content Helpers
//
.visible-xs-inline-block,
.visible-sm-inline-block,
.visible-sl-inline-block,
.visible-md-inline-block,
.visible-lg-inline-block,
.visible-xl-inline-block,
.visible-xs,
.visible-sm,
.visible-sl,
.visible-md,
.visible-lg,
.visible-xl {
	display: none !important;
}

.hidden {
	display: none !important;
}

@media (max-width: $breakpoint-sm - 1px) {
	.visible-xs-inline-block { display: inline-block !important; }
	.visible-xs { display: block !important; }
	.hidden-xs  { display: none !important; }
}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-sl - 1px) {
	.visible-sm-inline-block { display: inline-block !important; }
	.visible-sm { display: block !important; }
	.hidden-sm  { display: none !important; }
}

@media (min-width: $breakpoint-sl) and (max-width: $breakpoint-md - 1px) {
	.visible-sl-inline-block { display: inline-block !important; }
	.visible-sl { display: block !important; }
	.hidden-sl  { display: none !important; }
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg - 1px) {
	.visible-md-inline-block { display: inline-block !important; }
	.visible-md { display: block !important; }
	.hidden-md  { display: none !important; }
}

@media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl - 1px) {
	.visible-lg-inline-block { display: inline-block !important; }
	.visible-lg { display: block !important; }
	.hidden-lg  { display: none !important; }
}

@media (min-width: $breakpoint-xl) {
	.visible-xl-inline-block { display: inline-block !important; }
	.visible-xl { display: block !important; }
	.hidden-xl  { display: none !important; }
}
