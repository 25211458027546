html.msie {
	.gallery__item {
		.video {
			.btn {
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
}

.gallery {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0;
	padding-left: 0;
	@include breakpoint(mid) {
		padding-top: 2.5vw;
	}

	&__item {
		margin-bottom: 5vw;
		position: relative;
		width: 100%;
		&::marker{
			content: none;
		}

		@include breakpoint(mid) {
			width: calc(50% - 2.5vw);

			&:nth-child(odd) {
				margin-right: 2.5vw;
			}

			&:nth-child(even) {
				margin-left: 2.5vw;
			}
		}

		.button-download {
			position: absolute;
			top: 8px;
			right: 8px;
			z-index: 5;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: color(white);
			border-radius: 50%;

			&:hover,
			&:focus {
				background: color(coolgray100);
			}

			img,
			svg {
				width: auto;
				height: 60%;
				min-height: 0;
				display: block;
			}
		}

		.video {
			aspect-ratio: 16 / 9;
			box-shadow: $shadow-default;
			border-radius: $border-radius;

			.video-poster {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				opacity: 1;
				transition: opacity .2s ease-out;

				&.hidden {
					display: none;
				}

				img {
					width: 100%;
					border-radius: $border-radius;
					aspect-ratio: 16 / 9;
					object-fit: cover;
				}

				.btn {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}

			video {
				width: 100%;
				border-radius: $border-radius;
				aspect-ratio: 16 / 9;
				object-fit: cover;

				&.focus-visible {
					outline: 2px solid color(red500);
					outline-offset: 2px;
				}
			}

		}


		.image {
			margin-top: 0;
			width: 100%;
			border: unset;
			padding: unset;

			&.focus-visible {
				box-shadow: none;
				outline: 2px solid color(red500);
				outline-offset: 2px;
			}

			> img {
				width: 100%;
				border-radius: $border-radius;
				box-shadow: $shadow-default;
				aspect-ratio: 16 / 9;
				object-fit: cover;
			}
		}

		.image__desc {
			padding-left: 5vw;
			margin-top: 8px;
		}
	}
}
