.timeline {
	position: relative;
	overflow-x: hidden;
	cursor: grab;

	&:active {
		cursor: grabbing;
	}
}

.timeline__line {
	position: absolute;
	background: color(red500);
	top: 100%;
	margin-top: -66px;
	height: 4px;
	width: 100%;
	z-index: 0;

	@include breakpoint(sm) {
		top: 50%;
		margin-top: -2px;
	}
}

.timeline__items {
	display: flex;
	transition: transform 0.5s ease-in-out;
}

.timeline__grid {
	display: grid;
	grid-template:
		"top bottom" 1fr
		". ." 128px
		/ 1fr 1fr;

	> :first-child {
		grid-area: top;
		align-self: end;
	}

	> :last-child:not(:first-child) {
		grid-area: bottom;
		align-self: end;
	}

	@include breakpoint(sm) {
		grid-template:
			"top ." 1fr
			". ." 128px
			". bottom" 1fr
			/ 1fr 1fr;

		> :last-child:not(:first-child) {
			align-self: start;
		}
	}
}

.timeline-button {
	background-color: color(red500);
	border: 0;
	width: 48px;
	height: 48px;
	color: #fff;
	padding: 12px;
	cursor: pointer;
	position: absolute;
	top: calc(50% - 24px);
	transition: background-color 0.3s ease-in-out;

	&:hover,
	&:focus {
		background: color(red600);
	}

	&[disabled] {
		background-color: #d7dce1;
		cursor: not-allowed;
	}

	&--prev {
		border-radius: 6px 0 0 6px;
		left: -48px;

		svg {
			transform: rotate(180deg);
		}
	}

	&--next {
		border-radius: 0 6px 6px 0;
		right: -48px;
	}
}

.timeline-item__content {
	background-color: #f0f3f5;
	border-radius: 6px;
	padding: 20px;
	width: 550px;
	max-width: calc(100vw - 100px);

	@include breakpoint(max) {
		width: 40vw;
	}

	h3 {
		font-size: 4vw;
		margin: 0;

		@include breakpoint(min) {
			font-size: 18px;
		}

		@include breakpoint(mid) {
			font-size: calc(18px * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		}

		@include breakpoint(max) {
			font-size: 1.25vw;
		}
	}

	p {
		margin: 1em 0 0;
	}
}

.timeline-item__connector {
	height: 80px;
	width: 32px;
	position: absolute;
	left: calc(50% - 16px);
	bottom: -80px;
	z-index: 100;


	.line {
		content: "";
		position: absolute;
		background: color(red500);
		left: 14px;
		top: 0;
		bottom: 16px;
		width: 4px;
	}


	.circle {
		content: "";
		position: absolute;
		border: 4px solid color(red500);
		background-color: #fff;
		border-radius: 32px;
		width: 16px;
		height: 16px;
		left: 50%;
		transform: translate(-50%, -50%);
		bottom: 0;
		transition: width 0.3s ease-in-out, height 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out;


		&:has(img, svg) {

			img,
			svg {
				content: "";
				position: absolute;
				z-index: 99999;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0.0;
				transition: width 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
			}
		}
	}

	&--active {
		.circle {
			width: 32px;
			height: 32px;
			transform: translate(-50%, 0) !important;

			&:has(img, svg) {
				background: color(red500);

				img,
				svg {
					width: 24px;
					height: 24px;
					opacity: 1.0;
				}
			}
		}
	}
}

.timeline-item {
	position: relative;
	z-index: 2;
	margin: 0 20px;
	transition: opacity 0.5s ease-in-out;

	&:not(.timeline-item--active) {
		opacity: 0;
	}

	&:last-child:not(:first-child) .timeline-item__connector {
		.line {
			bottom: 16px;
		}

		.circle {
			transform: translate(-50%, -50%);
		}
	}


	@include breakpoint(sm) {
		opacity: 1 !important;



		&:last-child:not(:first-child) .timeline-item__connector {
			bottom: auto;
			top: -80px;

			.line {
				top: 16px;
				bottom: 0;
			}

			.circle {
				transform: translate(-50%, 50%);
				top: 0;
				bottom: auto;
			}
		}
	}
}



// For Backend edit reasons
.section--timeline .carbon-cbd__content-collection {
	display: flex;
	flex-wrap: wrap;
	gap: 0 30px;
	justify-content: center;
}



.timeline__scroller {
	position: relative;
}


.timeline__months>strong {
	text-align: left;
	padding: 0 1em;
}

.timeline__months>strong>span {
	position: sticky;
	left: 3em;
}
