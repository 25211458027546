.db-timeline__verticalline {
	display: block;
	position: absolute;
	top: -40px;
	left: calc(50% - 2px);
	height: 0;
	width: 3px;
	background-color: color(red500);
	z-index: -1;

	@include breakpoint(mid) {
		top: 0;
	}

	@include breakpoint(max) {
		width: 4px;
	}
}

