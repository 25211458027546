.fullwidthmap {

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;

    &.open {
      opacity: 1;
      pointer-events: auto;
      z-index: 9999;
      transition: opacity 0.3s ease-in-out;
    }
  }

  .overlay-close {
    position: absolute;
    top: 25px;
    right: 25px;

    background: transparent;
    border: 0;
    padding: 0;

    img {
      display: block;
      width: 25px;
      height: 25px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}