//
// Spacer
//

%spacerbottom--zero {
	margin-bottom: 0;
}
.section.spacerbottom--zero, .spacerbottom--zero {
	@extend %spacerbottom--zero;
}

%spacerbottom--small {
	margin-bottom: 2.5vw;
}
.section.spacerbottom--small, .spacerbottom--small {
	@extend %spacerbottom--small;
}

%spacerbottom--medium {
	margin-bottom: 5vw;
}
.section.spacerbottom--medium, .spacerbottom--medium {
	@extend %spacerbottom--medium;
}

%spacerbottom--big {
	margin-bottom: 10vw;
}
.section.spacerbottom--big, .spacerbottom--big {
	@extend %spacerbottom--big;
}

%padding--zero {
	padding: 0;
}
.section.padding--zero, .padding--zero {
	@extend %padding--zero;
}

%padding--small {
	padding: 2.5vw 0;
}
.section.padding--small, .padding--small {
	@extend %padding--small;
}

%padding--medium {
	padding: 5vw 0;
}
.section.padding--medium, .padding--medium {
	@extend %padding--medium;
}

%padding--big {
	padding: 10vw 0;
}
.section.padding--big, .padding--big {
	@extend %padding--big;
}

%padding-top--small {
	padding-top: 2.5vw;
}
.section.padding-top--small, .padding-top--small {
	@extend %padding-top--small;
}

%padding-top--medium {
	padding-top: 5vw;
}
.section.padding-top--medium, .padding-top--medium {
	@extend %padding-top--medium;
}

%padding-top--big {
	padding-top: 10vw;
}
.section.padding-top--big, .padding-top--big {
	@extend %padding-top--big;
}

%padding-bottom--small {
	padding-bottom: 2.5vw;
}
.section.padding-bottom--small, .padding-bottom--small {
	@extend %padding-bottom--small;
}

%padding-bottom--medium {
	padding-bottom: 5vw;
}
.section.padding-bottom--medium, .padding-bottom--medium {
	@extend %padding-bottom--medium;
}

%padding-bottom--big {
	padding-bottom: 10vw;
}
.section.padding-bottom--big, .padding-bottom--big {
	@extend %padding-bottom--big;
}

@include breakpoint(0, $breakpoint-mid - 1) {
	%spacerbottom--sm-zero {
		margin-bottom: 0;
	}
	%spacerbottom--sm-small {
		margin-bottom: 2.5vw;
	}
	%spacerbottom--sm-medium {
		margin-bottom: 5vw;
	}
	%spacerbottom--sm-big {
		margin-bottom: 10vw;
	}
}

.section.spacerbottom--sm-zero {
	@extend %spacerbottom--sm-zero;
}

.section.spacerbottom--sm-small {
	@extend %spacerbottom--sm-small;
}

.section.spacerbottom--sm-medium {
	@extend %spacerbottom--sm-medium;
}

.section.spacerbottom--sm-big {
	@extend %spacerbottom--sm-big;
}
