.db-timeline__branch {
	@include breakpoint(mid) {
		display: block;
		position: absolute;
		top: 50%;
		right: auto;
		bottom: auto;
		left: 0;
		height: 3px;
		width: 30%;
		margin-left: 0;
		background-color: color(red500);
		z-index: -1;
	}

	@include breakpoint(max) {
		height: 4px;
	}

	.db-timeline--even & {
		@include breakpoint(mid) {
			left: auto;
			right: 0;
		}
	}
}

.db-timeline__branch::after {
	position: absolute;
	content: '';
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 3px solid color(red500);
	background-color: color(white);
	top: -40px;
	z-index: 1;

	.db-timeline--odd & {
		left: 50%;
		transform: translate(-50%, -50%);

		@include breakpoint(mid) {
			left: auto;
			right: 0;
			top: 50%;
			transform: translate(50%, -50%);
		}
	}

	.db-timeline--even & {
		left: 50%;
		transform: translate(-50%, -50%);

		@include breakpoint(mid) {
			left: 0;
			top: 50%;
		}
	}


	.db-timeline--finished & {
		display: block;
		width: 32px;
		height: 32px;
		background-color: color(white);
		background-size: 60%;
		background-repeat: no-repeat;
		background-position: center center;
		@include background-svg('<svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M.333 7.44c0-.435.374-.75.773-.75.3 0 .374.05.548.219l4.088 3.943L15.987.884a.726.726 0 01.549-.217c.448 0 .797.387.797.75 0 .193-.074.363-.249.532l-10.793 10.5a.786.786 0 01-.549.218.726.726 0 01-.548-.218L.558 7.973a.74.74 0 01-.225-.532z" fill="#EC0016" fill-rule="nonzero"/></svg>');
	}


	.db-timeline--active & {
		width: 32px;
		height: 32px;
		background-color: color(red500);
		background-size: 75%;
		background-repeat: no-repeat;
		background-position: center center;
		@include background-svg('<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M12.971 1.671l-.104 1.507 1.61.92 1.256-.816a.66.66 0 01.356-.125c1.015 0 2.971 3.653 2.971 4.645a.68.68 0 01-.377.606l-1.339.67v1.841l1.34.67a.68.68 0 01.376.607c0 1.018-2.076 4.645-2.971 4.645a.66.66 0 01-.356-.126l-1.255-.816-1.611.92.104 1.486c.061.87-1.604 1.026-2.971 1.026-1.291 0-3.034-.134-2.971-1.026l.104-1.485-1.61-.92-1.256.815a.66.66 0 01-.356.126C2.968 16.84.94 13.2.94 12.175c0-.23.15-.473.377-.586l1.339-.67V9.08l-1.34-.67a.68.68 0 01-.376-.607c0-.99 2.01-4.645 2.971-4.645a.66.66 0 01.356.125l1.255.816 1.611-.92L7.05 1.67C7.004.841 8.499.677 9.864.667H10c1.293 0 3.031.14 2.971 1.004zM10 1.985c-.523 0-1.067.063-1.59.167l.083 1.36a.663.663 0 01-.334.607L5.836 5.46a.633.633 0 01-.335.083.623.623 0 01-.355-.105l-1.13-.753a8.029 8.029 0 00-1.59 2.783l1.192.607a.663.663 0 01.377.586v2.678a.66.66 0 01-.377.586l-1.213.606a8.131 8.131 0 001.61 2.762l1.13-.732a.663.663 0 01.356-.125.66.66 0 01.335.104l2.323 1.318c.212.12.35.377.334.628l-.083 1.36a8.213 8.213 0 001.59.168c.523 0 1.067-.063 1.59-.168l-.083-1.36a.693.693 0 01.334-.628l2.323-1.318a.66.66 0 01.335-.104c.125 0 .242.053.355.125l1.151.732a8.436 8.436 0 001.59-2.762l-1.213-.606a.66.66 0 01-.377-.586V8.66a.66.66 0 01.377-.586l1.213-.607a8.43 8.43 0 00-1.61-2.783l-1.13.753a.623.623 0 01-.356.105.633.633 0 01-.335-.084L11.84 4.12a.663.663 0 01-.334-.607l.083-1.36A8.213 8.213 0 0010 1.985zm0 4.682A3.328 3.328 0 0113.333 10 3.328 3.328 0 0110 13.333 3.328 3.328 0 016.667 10 3.328 3.328 0 0110 6.667zM10 8a2 2 0 10.001 4.001A2 2 0 0010 8z" fill="#FFF" fill-rule="nonzero"/></svg>');
	}
}
