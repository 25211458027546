.db-timeline__button {
	display: flex;
	align-items: center;
	width: 100%;
	border: none;
	text-align: left;
	padding: 20px;
	background-color: transparent;
	transition: $transition;

	@include breakpoint(mid) {
		background-position: right 2vw center;
		padding: 1.5vw;
	}

	@include breakpoint(max) {
		line-height: 1.5vw;
		padding: 0.75vw 1vw;
	}

	&.focus-visible {
		color: color(red500);
		border-radius: $border-radius;
		box-shadow: $box-shadow--1;
	}

	&:focus {
		outline: none;
	}

	&:hover {
		color: color(red500);
		cursor: pointer;
	}

	.db-timeline__title.db-timeline--open & {
		color: color(red500);
	}
}

.db-timeline__id {
	@include font-family(headblack);
	flex: 0 0 32px;
	font-size: 9vw;
	line-height: 10vw;
	margin-right: 5vw;
	text-align: center;

	@include breakpoint(min) {
		flex: 0 1 6vw;
		font-size: 36px;
		line-height: 40px;
		margin-right: 20px;
	}

	@include breakpoint(mid) {
		flex: 0 1 auto;
		min-width: 48px;
		font-size: calc(36px + (64 - 36) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(40px + (72 - 40) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-right: 1.5vw ;
	}

	@include breakpoint(max) {
		font-size: 4vw;
		line-height: 4.5vw;
		margin-right: 1vw;
	}

	.db-timeline__title.db-timeline--open &,
	.db-timeline__button:focus &,
	.db-timeline__button:hover & {
		svg,
		svg path,
		svg .st0 {
			fill: color(red500);
		}
	}
}

.db-timeline__buttontext {
	@include font-family(headblack);
	font-size: 4vw;
	line-height: 6vw;
	flex: 1 1 auto;

	@include breakpoint(min) {
		font-size: 16px;
		line-height: 24px;
	}

	@include breakpoint(mid) {
		font-size: calc(16px + (20 - 16) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(24px + (32 - 24) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
	}

	.no-msie & {
		display: flex;
		align-items: center;
		flex-grow: 1;
		min-height: 48px;
	}
}

.db-timeline__toggleicon {
	// outline: 1px solid blue;
	flex: 0 0 auto;
	display: block;
	width: 20px;
	height: 20px;
	margin-left: 5vw;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 11c.562 0 1 .406 1 1 0 .25-.094.5-.281.719l-8 8a1.101 1.101 0 01-.719.28c-.25 0-.5-.093-.719-.28l-8-8a1.101 1.101 0 01-.28-.719c0-.25.093-.5.28-.719a1.1 1.1 0 01.72-.28c.25 0 .5.093.718.28L16 18.594l7.281-7.313C23.5 11.094 23.75 11 24 11z" fill="#282D37" fill-rule="nonzero"/></svg>');

	.db-timeline__button:focus &,
	.db-timeline__button:hover & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 11c.562 0 1 .406 1 1 0 .25-.094.5-.281.719l-8 8a1.101 1.101 0 01-.719.28c-.25 0-.5-.093-.719-.28l-8-8a1.101 1.101 0 01-.28-.719c0-.25.093-.5.28-.719a1.1 1.1 0 01.72-.28c.25 0 .5.093.718.28L16 18.594l7.281-7.313C23.5 11.094 23.75 11 24 11z" fill="#ec0016" fill-rule="nonzero"/></svg>');
	}

	.db-timeline__button[aria-expanded=true] & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 21c.562 0 1-.407 1-1 0-.25-.094-.5-.281-.719l-8-8A1.101 1.101 0 0016 11c-.25 0-.5.094-.719.281l-8 8a1.101 1.101 0 00-.28.719c0 .25.093.5.28.718a1.1 1.1 0 00.72.282c.25 0 .5-.094.718-.282L16 13.406l7.281 7.312c.219.188.469.282.719.282z" fill="#ec0016" fill-rule="nonzero"/></svg>');
	}

	.db-timeline__button[aria-expanded=true]:focus &,
	.db-timeline__button[aria-expanded=true]:hover & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 21c.562 0 1-.407 1-1 0-.25-.094-.5-.281-.719l-8-8A1.101 1.101 0 0016 11c-.25 0-.5.094-.719.281l-8 8a1.101 1.101 0 00-.28.719c0 .25.093.5.28.718a1.1 1.1 0 00.72.282c.25 0 .5-.094.718-.282L16 13.406l7.281 7.312c.219.188.469.282.719.282z" fill="#ec0016" fill-rule="nonzero"/></svg>');
	}

	@include breakpoint(mid) {
		margin-left: 1vw;
	}

	@include breakpoint(max) {
		width: 32px;
		height: 32px;
		margin-left: 2vw;
	}
}
