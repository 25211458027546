@mixin translate-down() {
  transform: translateY(4vw);
}

.section-background {
  &--colored {
    @include breakpoint(mid) {
      .textmedia::before {
        content: "";
        display: block;
        grid-row: 1/-1;
        grid-column: 6/-1;
        margin-right: -2.5vw;
        margin-bottom: -1.5vw;
        border-radius: $border-radius;
      }
    }

    .textmedia.textmedia--shifted-down::before {
      @include translate-down();
    }
  }

  &--coolgray100 {
    .textmedia::before {
      @include breakpoint(mid) {
        background: color(coolgray100);
      }
    }
  }

  &--red500 .textmedia {
    @include breakpoint(mid) {
      color: color(white);
      &::before {
        background: color(red500);
      }
      .textmedia__text,
      .textmedia__textaddon{
        background: color(red500);
      }
      .textmedia__textaddon .list--arrow a {
        color: color(white);

        &:hover {
          color: color(coolgray100);
        }
      }

      .btn {
        background-color: color(white);
        color: color(red500);

        &:hover {
          background-color: color(coolgray100);
        }
      }
    }
  }
}

//
// Default: Media left; Text right
//
.textmedia {
  position: relative;
  // outline: 1px solid rebeccapurple;

  @include breakpoint(mid) {
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    grid-template-rows: auto auto;
  }

  @include breakpoint(max) {
    grid-template-columns: repeat(16, 1fr);
  }

  img {
    border-radius: $border-radius;
  }

  .db-neos-media__image-caption {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
  }

  .db-neos-media__image-meta{
    border-bottom-right-radius: $border-radius;
  }

  figure {
    margin: 0;
  }
}

// Media Item
.textmedia__media {
  margin-bottom: 5vw;

  @include breakpoint(mid) {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: span 10;
    align-self: end;
    margin-bottom: 0;
    margin-right: 2.5vw;
  }

  @include breakpoint(max) {
    grid-column-end: span 9;
    margin-right: 0;
  }

  .image__desc,
  .video__infos {
    @include breakpoint(mid) {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
    }
  }
}

// Text Column + Extra Text Cell
.textmedia__text,
.textmedia__textaddon {
  margin-left: 5vw;
  margin-right: 5vw;

  @include breakpoint(min) {
    margin-left: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
    margin-right: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
    margin-bottom: 2vw;
  }

  @include breakpoint(mid) {
    grid-row: 1;
    grid-column-start: 11;
    grid-column-end: span 8;
    align-self: end;
    margin-left: 2.5vw;
    margin-right: 0;
    margin-bottom: 0;
  }

  @include breakpoint(max) {
    grid-column-end: span 6;
    margin-left: 0;
  }
}

//
.textmedia__text {
  margin-bottom: 24px;

  p:last-child {
    margin-bottom: 0;
  }
}

// Extra Text Cell
.textmedia__textaddon {
  margin-top: 24px;

  @include breakpoint(mid) {
    grid-row: 2;
    margin-top: 3vw;
  }

  @include breakpoint(max) {
    margin-top: 2vw;
  }
}

//
// Text left, Media Item right
//
.textmedia--reversed {
  // outline: 1px solid rebeccapurple;

  @include breakpoint(mid) {
    &.textmedia::before {
      grid-column: 1/-5;
      margin-left: -2.5vw;
    }
  }

  .textmedia__media {
    @include breakpoint(mid) {
      grid-column-start: 9;
      margin-right: 0;
      margin-left: 2.5vw;
    }

    @include breakpoint(max) {
      grid-column-start: 8;
      margin-left: 0;
    }
  }

  .textmedia__text,
  .textmedia__textaddon {
    @include breakpoint(mid) {
      grid-column-start: 1;
      margin-left: 0;
      margin-right: 2.5vw;
    }

    @include breakpoint(max) {
      margin-right: 0;
    }
  }
}

//
// Text Media with Video Element
//
.textmedia__media {
  .textmedia__videowrapper {
    position: relative;
    padding-top: 56.15%;
    border-radius: $border-radius;
    background-color: color(bright);
    overflow: hidden;
  }

  .textmedia__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: contain;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

//
// Text Media aligned top (Glossary
//

.textmedia {
  @include breakpoint(mid) {
    &.textmedia--topaligned {
      .textmedia__media {
        align-self: baseline;
        margin-top: 4vw;
      }
    }

    &.textmedia--topaligned-doubled {
      .textmedia__media {
        align-self: baseline;
        margin-top: 6vw;
      }
    }
  }
}

//
// Text Media with Text aligned
//

.textmedia--aligned {
  @include breakpoint(mid) {
    grid-template-rows: 1fr auto;

    .textmedia__text {
      grid-row-end: 2;
    }

    .textmedia__media {
      grid-row-end: -1;
    }
  }
}

.textmedia--no-image {
  @include breakpoint(mid) {
    &.textmedia::before {
      margin-top: -2vw;
    }
  }
}

.textmedia-text--centered {
  @include breakpoint(mid) {
    .textmedia__text {
      align-self: center;
    }
  }
}

.textmedia-text--up {
  @include breakpoint(mid) {
    .textmedia__text {
      align-self: start;
    }
  }
}

.textmedia--shifted-down {
  .textmedia__textaddon {
    @include breakpoint(mid) {
      @include translate-down();
    }
  }

  .textmedia__text {
    @include breakpoint(mid) {
      margin-top: 2vw;
      @include translate-down();
    }
  }
}

.section-background--colored .textmedia.textmedia--aligned {
  &::before {
    margin-top: -2vw;
  }
}
