.db-timeline__collapse {
	margin-top: 0;
	padding: 0 20px 3vw 20px;
	height: 0;
	transition: all $transition-time $transition-function;

	.db-timeline__inner:not(.db-timeline--open) & {
		display: none;
	}

	@include breakpoint(mid) {
		padding: 0 1.5vw;
	}

	@include breakpoint(max) {
		padding: 0 1vw;
	}

	p:last-child {
		margin-bottom: 0;
	}

	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 3vw;

		@include breakpoint(mid) {
			height: 30px;
		}

		@include breakpoint(max) {
			height: 12px;
		}
	}
}

// Transition classes to handle overflow during transition
// @see https://stackoverflow.com/questions/27904886/can-i-apply-a-css-transition-to-the-overflow-property#answer-41221543
.db-timeline--no-overflow {
  .db-timeline__collapse {
    overflow: hidden;
    animation: $transition-time delay-no-overflow;
  }
}

@keyframes delay-no-overflow {
  from { overflow: visible; }
}

.db-timeline--overflow {
  .db-timeline__collapse {
    overflow: visible;
    animation: $transition-time delay-overflow;
  }
}

@keyframes delay-overflow {
  from { overflow: hidden; }
}
