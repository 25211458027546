//*******************************************************************************
// Teaser - with Big Image
//*******************************************************************************


.teaser--image {
	margin: 0 5vw;
	overflow: hidden;

	.teaser__picture {
		display: block;
		position: relative;
		z-index: -1;

		img {
			position: relative;
			height: 300px;
			width: 100%;
			object-fit: cover;
			border-radius: $border-radius;

			@include breakpoint(mid) {
				height: auto;
			}
		}
	}

	.teaser__inner {
		position: relative;
		margin: -15vw 5vw 0 5vw;
		background-color: color(bright);
		border-radius: $border-radius;

		@include breakpoint(min) {
			margin-left:  calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
			margin-right: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
		}

		@include breakpoint(mid) {
			margin-top: -12vw;
			margin-left: 5vw;
			margin-right: 5vw;
		}

		@include breakpoint(max) {
			margin-left: 5vw;
			margin-right: 5vw;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 90%;
			box-shadow: $box-shadow--3;
			border-radius: $border-radius $border-radius 50% 50%;
			z-index: -1;
		}
	}

	.teaser__title,
	.teaser__content {
		position: relative;
		z-index: 3;
	}

	.teaser__title {
		padding: 4vw;
		margin-bottom: 4vw;

		@include breakpoint(mid) {
			margin-bottom: 0;
		}
	}

	.teaser__content {
		padding-left: 4vw;
		padding-right: 4vw;

		@include breakpoint(mid) {
			padding-left: 2vw;
			padding-right: 2vw;
		}

		@include breakpoint(max) {
			padding-left: 4vw;
			padding-right: 4vw;
		}
	}

	.btn {
		margin-bottom: 4vw;

		@include breakpoint(mid) {
			margin-bottom: 0;
		}
	}
}



//
// Teaser Image with single line Headline
//
.teaser--image-has-shorttitle {
	.teaser__inner {
		margin-top: -7.5vw;

		@include breakpoint(mid) {
			margin-top: -6vw;
		}
	}
}



//
// Teaser Subpage
//
.teasersubpage--image {

	@include breakpoint(mid) {
		margin-left: 25vw;
		margin-right: 25vw;
	}

	@include breakpoint(max) {
		margin-left: 20vw;
		margin-right: 20vw;
	}

	.teaser__picture {
		img {
			min-height: 225px;

			@include breakpoint(mid) {
				min-height: 300px;
			}

			@include breakpoint(max) {
				min-height: 600px;
			}
		}
	}

	.teaser__inner {
		@include breakpoint(mid) {
			margin-left: 5vw;
			margin-right: 5vw;
		}
	}

	.teaser__content {
		max-width: 575px;

		@include breakpoint(mid) {
			max-width: none;
		}
	}
}
