//*******************************************************************************
// SPACER
//
// REGEX
//
// Regular Expression to find Padding-Spacer Classes: [\s|"][p][0-5]|[p][t|r|b|l|x|y][0-5][\s|"]
// Regular Expression to find Margin-Spacer  Classes: [\s|"][m][0-5]|[m][t|r|b|l|x|y][0-5][\s|"]
//
//*******************************************************************************



.p0 {
	padding: 0 !important;
}

.p1 {
	padding: $spacer * 1 !important;
}

.p2 {
	padding: $spacer * 2 !important;
}

.p3 {
	padding: $spacer * 3 !important;
}

.p4 {
	padding: $spacer * 4 !important;
}

.p5 {
	padding: $spacer * 5 !important;
}

.pt0 {
	padding-top: 0 !important;
}

.pt1 {
	padding-top: $spacer * 1 !important;
}

.pt2 {
	padding-top: $spacer * 2 !important;
}

.pt3 {
	padding-top: $spacer * 3 !important;
}

.pt4 {
	padding-top: $spacer * 4 !important;
}

.pt5 {
	padding-top: $spacer * 5 !important;
}

.pr0 {
	padding-right: 0 !important;
}

.pr1 {
	padding-right: $spacer * 1 !important;
}

.pr2 {
	padding-right: $spacer * 2 !important;
}

.pr3 {
	padding-right: $spacer * 3 !important;
}

.pr4 {
	padding-right: $spacer * 4 !important;
}

.pr5 {
	padding-right: $spacer * 5 !important;
}

.pb0 {
	padding-bottom: 0 !important;
}

.pb1 {
	padding-bottom: $spacer * 1 !important;
}

.pb2 {
	padding-bottom: $spacer * 2 !important;
}

.pb3 {
	padding-bottom: $spacer * 3 !important;
}

.pb4 {
	padding-bottom: $spacer * 4 !important;
}

.pb5 {
	padding-bottom: $spacer * 5 !important;
}

.pl0 {
	padding-left: 0 !important;
}

.pl1 {
	padding-left: $spacer * 1 !important;
}

.pl2 {
	padding-left: $spacer * 2 !important;
}

.pl3 {
	padding-left: $spacer * 3 !important;
}

.pl4 {
	padding-left: $spacer * 4 !important;
}

.pl5 {
	padding-left: $spacer * 5 !important;
}

.px0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.px1 {
	padding-left: $spacer * 1 !important;
	padding-right: $spacer * 1 !important;
}

.px2 {
	padding-left: $spacer * 2 !important;
	padding-right: $spacer * 2 !important;
}

.px3 {
	padding-left: $spacer * 3 !important;
	padding-right: $spacer * 3 !important;
}

.px4 {
	padding-left: $spacer * 4 !important;
	padding-right: $spacer * 4 !important;
}

.px5 {
	padding-left: $spacer * 5 !important;
	padding-right: $spacer * 5 !important;
}

.py0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.py1 {
	padding-top: $spacer * 1 !important;
	padding-bottom: $spacer * 1 !important;
}

.py2 {
	padding-top: $spacer * 2 !important;
	padding-bottom: $spacer * 2 !important;
}

.py3 {
	padding-top: $spacer * 3 !important;
	padding-bottom: $spacer * 3 !important;
}

.py4 {
	padding-top: $spacer * 4 !important;
	padding-bottom: $spacer * 4 !important;
}

.py5 {
	padding-top: $spacer * 5 !important;
	padding-bottom: $spacer * 5 !important;
}


.m0 {
	margin: 0 !important;
}

.m1 {
	margin: $spacer * 1 !important;
}

.m2 {
	margin: $spacer * 2 !important;
}

.m3 {
	margin: $spacer * 3 !important;
}

.m4 {
	margin: $spacer * 4 !important;
}

.m5 {
	margin: $spacer * 5 !important;
}

.mt0 {
	margin-top: 0 !important;
}

.mt1 {
	margin-top: $spacer * 1 !important;
}

.mt2 {
	margin-top: $spacer * 2 !important;
}

.mt3 {
	margin-top: $spacer * 3 !important;
}

.mt4 {
	margin-top: $spacer * 4 !important;
}

.mt5 {
	margin-top: $spacer * 5 !important;
}

.mt6 {
	margin-top: $spacer * 5 !important;
}

.mt7 {
	margin-top: $spacer * 5 !important;
}

.mt8 {
	margin-top: $spacer * 5 !important;
}

.mt9 {
	margin-top: $spacer * 5 !important;
}

.mt10 {
	margin-top: $spacer * 5 !important;
}

.mr0 {
	margin-right: 0 !important;
}

.mr1 {
	margin-right: $spacer * 1 !important;
}

.mr2 {
	margin-right: $spacer * 2 !important;
}

.mr3 {
	margin-right: $spacer * 3 !important;
}

.mr4 {
	margin-right: $spacer * 4 !important;
}

.mr5 {
	margin-right: $spacer * 5 !important;
}

.mb0 {
	margin-bottom: 0 !important;
}

.mb1 {
	margin-bottom: $spacer * 1 !important;
}

.mb2 {
	margin-bottom: $spacer * 2 !important;
}

.mb3 {
	margin-bottom: $spacer * 3 !important;
}

.mb4 {
	margin-bottom: $spacer * 4 !important;
}

.mb5 {
	margin-bottom: $spacer * 5 !important;
}

.ml0 {
	margin-left: 0 !important;
}

.ml1 {
	margin-left: $spacer * 1 !important;
}

.ml2 {
	margin-left: $spacer * 2 !important;
}

.ml3 {
	margin-left: $spacer * 3 !important;
}

.ml4 {
	margin-left: $spacer * 4 !important;
}

.ml5 {
	margin-left: $spacer * 5 !important;
}

.mx0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.mx1 {
	margin-left: $spacer * 1 !important;
	margin-right: $spacer * 1 !important;
}

.mx2 {
	margin-left: $spacer * 2 !important;
	margin-right: $spacer * 2 !important;
}

.mx3 {
	margin-left: $spacer * 3 !important;
	margin-right: $spacer * 3 !important;
}

.mx4 {
	margin-left: $spacer * 4 !important;
	margin-right: $spacer * 4 !important;
}

.mx5 {
	margin-left: $spacer * 5 !important;
	margin-right: $spacer * 5 !important;
}

.my0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.my1 {
	margin-top: $spacer * 1 !important;
	margin-bottom: $spacer * 1 !important;
}

.my2 {
	margin-top: $spacer * 2 !important;
	margin-bottom: $spacer * 2 !important;
}

.my3 {
	margin-top: $spacer * 3 !important;
	margin-bottom: $spacer * 3 !important;
}

.my4 {
	margin-top: $spacer * 4 !important;
	margin-bottom: $spacer * 4 !important;
}

.my5 {
	margin-top: $spacer * 5 !important;
	margin-bottom: $spacer * 5 !important;
}




/* negative margins */

/* top */

.mt-1 {
	margin-top: $spacer * -1 !important;
}

.mt-2 {
	margin-top: $spacer * -2 !important;
}

.mt-3 {
	margin-top: $spacer * -3 !important;
}

.mt-4 {
	margin-top: $spacer * -4 !important;
}

.mt-5 {
	margin-top: $spacer * -5 !important;
}

.mt-6 {
	margin-top: $spacer * -6 !important;
}

.mt-7 {
	margin-top: $spacer * -7 !important;
}

.mt-8 {
	margin-top: $spacer * -8 !important;
}

.mt-9 {
	margin-top: $spacer * -9 !important;
}

.mt-10 {
	margin-top: $spacer * -10 !important;
}
