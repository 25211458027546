.news101-item {
	display: grid;
	grid-template-columns: min(3vw, 1em) min(3vw, 1em) 1fr auto min(3vw, 1em) min(3vw, 1em);
	grid-template-rows: min(3vw, 1em) auto auto auto 1fr auto min(3vw, 1em);
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(#737373, 0.5);
	background: color(coolgray100);
	width: 100%;
	overflow: hidden;
	min-height: 100%;
	color: color(black);

	.linkbutton {
		color: color(black);
	}

	&:hover,
	&:focus {
		color: color(black);

		.linkbutton {
			color: color(red600);
			text-decoration: underline;

			&::after {
				background-position: left 12px top 2px;
			}
		}
	}

}

.news101-item--hidden,
.news101-item--hidden-by-pagination,
.news101-item--hidden-by-tag,
.news101-item--hidden-by-category {
	display: none;
}

.news101-item__meta,
.news101-item__category {
	@include scaling(font-size, (0:0, 400:12, 1600: 16));
	@include scaling(line-height, (0:0, 400:24, 1600: 32));
}

.news101-item__meta {
	align-self: center;
	justify-self: start;
	grid-row: 2;
	grid-column: 2/4;
	margin: 0 1em 0 0;
}

.news101-item__meta__tag::before {
	content: "\2014";
	margin-right: 0.25rem;
}

.news101-item__category {
	align-self: start;
	justify-self: end;
	grid-row: 2;
	grid-column: 4/6;
	background: color(red500);
	color: color(white);
	white-space: nowrap;
	border-radius: 2em;
	padding: .25em 1em;
	margin: 0;
	position: relative;
	z-index: 1;
}

.news101-item__image {
	display: none;
}

.news101-item__symbol {
	display: none;
}

.news101-item__content {
	grid-row: 3/6;
	grid-column: 3/5;
	margin-top: 1.5em;

	& > p {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 4;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.news101-item__link {
	align-self: end;
	margin-bottom: 0;
	grid-row: 6;
	grid-column: 3/5;
}

.news101-item__content h3,
.news101-item__link {
	@include scaling(font-size, (0:0, 600: 20, 800: 20));
	@include scaling(line-height, (0:0, 600: 26, 800: 32));
}

/* with image */
.news101-item--with-image {

	.news101-item__image-wrapper {
		display: block;
		grid-row: 1/5;
		grid-column: 1/-1;
		position: relative;
		object-fit: cover;
		aspect-ratio: 4/3;

		.news101-item__image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			aspect-ratio: 4/3;
			display: block;
		}

		.news101-item__image-meta {
			position: absolute;
			right: -1px;
			bottom: -1px;
			display: block;
			color: color(black);
			background-color: color(coolgray100);
			font-size: 10px;
			line-height: 10px;
			padding: 5px 10px;
			margin: 0;
			border-top-left-radius: $border-radius;
			z-index: 2;
		}
	}


	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 5em;
		align-self: end;
		grid-row: 1/5;
		grid-column: 1/-1;
		z-index: 1;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.92) 100%);
	}

	.news101-item__content {
		grid-row: 5;
	}

	.news101-item__meta {
		grid-row: 4;
		grid-column: 3/5;
		color: color(white);
		z-index: 2;
		line-height: 1;
		margin-bottom: min(3vw, 2em);
	}
}

.news101-item--pdf {

	.news101-item__meta {
		grid-row: 4;
		grid-column: 3/5;
	}

	.news101-item__content {
		grid-row: 5;
	}

	.news101-item__symbol {
		display: block;
		grid-row: 3;
		grid-column: 3/5;
		margin: 1em 0;
		width: 50%;
		justify-self: center;
		align-self: center;
	}

	.linkbutton::after {
		transform: rotate(90deg);
	}
}

.news101-item--collapsed {

	.news101-item__symbol,
	.news101-item__image,
	&::after,
	.news101-item__meta__tag,
	.news101-item__content p {
		display: none;
	}

	.news101-item__meta {
		grid-row: 2;
		grid-column: 3;
		margin-bottom: 0;
	}

	.news101-item__meta-date {
		color: #000;
	}
}

// special case with alternating background colors
.news101-list--alt-background .news101-item, .news101-s-h__slider--alt-background .news101-item {
	background: #002b59;
	color: #fff;

	.news101-item__link {
		color: #fff;

		&::after {
			// this is a style originally defined in the DB.Neos.Button Package.
			// IMO it is bad practice to color code directly in SVG but don't want to change the button package
			@include background-svg('<svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#FFFFFF" stroke-width="1.333" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>');
		}
	}
	.news101-item__category {
		background: #fff;
		color: #002b59;
	}

	.news101-item__meta-date {
		color: #fff;
	}
}

/* TODOS:
- margins anpassen vw verhaut das ganze in die länge
*/
