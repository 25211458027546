/*
	DB.Neos.ImageBlend / Main.scss

	Diese Datei enthält alle Styles, die für die im Paket enthaltenen
	Module benötigt werden. Hier können Variablen und Vererbungen
	aus dem DB.Neos.Base Paket verwendet werden.

	Projektspezifische Änderungen an den Komponenten dieses Paketes
	finden im Projekt-Paket (z.B. DB.Hochwasser) statt.
*/

.image-blend {
	$self: &;
	$indicator-overlap: 2.25vw; // how much the indicator-line will overlap the image
	padding-top: $indicator-overlap;

	&__media {
		position: relative;
	}

	// wrapper for our absolute positioned image
	&__image-wrapper {
		position: absolute;
		top: 0;
		height: 100%;
		left: 0;
		right: 50%;
		overflow: hidden;

	}

	&__image {
		border-radius: $border-radius;

		// this is relavant for elements size
		&--right {
			display: block;
			width: 100%;
			height: auto;
		}

		//this will be cutt off by its container which is controlled by the sliding button
		&--left {
			position: absolute;
			left: 0;
			height: 100%;
			width: auto;
			min-width: 100%;
			max-width: none;
		}
	}


	&__label {
		position: absolute;
		z-index: 3;
		top: 0;
		background-color: color(red500);
		color: color(white);
		@include font-family(bold);
		padding: 0 1.25vw;

		&--left {
			left: 0;
			border-radius: $border-radius 0 $border-radius 0;
		}

		&--right {
			right: 0;
			border-radius: 0 $border-radius 0 $border-radius;
		}
	}

	&__indicator {
		z-index: 2;
		position: absolute;
		left: 50%;
		width: 4px;
		margin-left: -2px;
		top: -$indicator-overlap;
		bottom: -$indicator-overlap;
		background-color: color(red500);
		transform: translateX(0);

		@include breakpoint(mid) {
			width: 6px;
			margin-left: -3px;
		}
	}

	&__button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);

		width: 50px;
		height: 50px;
		display: block;
		outline: none;
		text-decoration: none;
		cursor: pointer;
		background-color: color(red500);
		border-radius: 50%;
		transition: $transition;
		border: 5px solid rgba(255, 255, 255, 0.5);
		background-clip: padding-box;

		&.focus-visible {
			box-shadow: 0 0 0 2px color(red500);
		}

		@include breakpoint(mid) {
			width: 6.25vw;
			height: 6.25vw;
			max-width: 70px;
			max-height: 70px;
		}

		&:focus,
		&:hover,
		&:active {
			outline: none;
			text-decoration: none;
			background-color: color(red500);

			#{$self}__icon--left {
				transform: translate3d(-2px, -50%, 0);
			}

			#{$self}__icon--right {
				transform: translate3d(2px, -50%, 0);
			}
		}


	}

	&__icon {
		display: block;
		position: absolute;
		width: 10px;
		height: 15px;
		transition: $transition;
		transform: translate3d(0, -50%, 0);
		top: 50%;

		@include breakpoint(mid) {
			width: 1vw;
			height: 1.875vw;
		}

		@include breakpoint(max) {
			width: 17px;
			height: 30px;
		}

		&--left {
			left: 12%;
		}

		&--right {
			right: 12%;
		}
	}

	&__legend {
		margin-top: calc(#{$indicator-overlap} + 12px);
		width: 320px;
		max-width: 100%;
	}
}
