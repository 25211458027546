.db-blog {
	&__image-slider {
		width: 100%;
		margin-top: 5vh;
		margin-bottom: 5vh;
	}

	&__slider-navigation {
		grid-column: 1/3;
		grid-row: 4;
		margin: 24px 0;
	}

	&__slider-pagination {
		display: flex;
		justify-content: center;
	}

	&__slider {

		&.swiper {
			overflow: visible;

			.swiper-slide {
				opacity: 0;
				transition: opacity 0.3s ease;

				&-active {
					opacity: 1;
				}
			}
		}

		.swiper-button-prev {
			left: 0;
			transform: translateX(-110%);
		}
		.swiper-button-next {
			right: 0;
			transform: translateX(110%);
		}
	}
}
