//*******************************************************************************
// Map Component
//*******************************************************************************

//
//
//
.image-highlighter {
	// outline: 1px solid rebeccapurple;
	display: flex;
	flex-direction: column-reverse;

	@include breakpoint(mid) {
		display: grid;
		grid-template-columns: repeat(18, 1fr);
		grid-template-rows: auto auto;
	}

	@include breakpoint(max) {
		grid-template-columns: repeat(16, 1fr);
	}
}

.image-highlighter--shadow {
	box-shadow: 0 0 1rem 0 #d7dce1;
	border-radius: 4px;
	transition: box-shadow .45s cubic-bezier(.23, 1, .32, 1);
	padding: 50px;

	&:hover {
		box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.23), 0 0.5rem 1.6rem 0 rgba(0, 0, 0, 0.20);
	}
}

//
// Media Item
//
.image-highlighter__media {
	margin-bottom: 5vw;

	@include breakpoint(mid) {
		grid-row: 1;
		grid-column-start: 1;
		grid-column-end: span 10;
		margin-bottom: 0;
		margin-right: 2.5vw;
	}

	@include breakpoint(max) {
		grid-column-end: span 9;
		margin-right: 0;
	}

	/* .image__desc,
    .video__infos {
        @include breakpoint(mid) {
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
        }
    } */
}


//
// Media Inner Wrapper
//
.image-highlighter__mediainner {
	// outline: 2px solid #000;
	position: relative;

	>.image-highlighter__image--overlay {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;

		transition: opacity 0.2s ease-in-out;

		&.active {
			opacity: 1;
		}
	}
}



//
// Text Column
//
.image-highlighter__content {
	margin-left: 5vw;
	margin-right: 5vw;

	@include breakpoint(min) {
		margin: 0 calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(mid) {
		grid-row: 1;
		grid-column-start: 11;
		grid-column-end: span 8;
		align-self: start;
		margin-left: 2.5vw;
		margin-right: 0;
	}

	@include breakpoint(max) {
		grid-column-end: span 6;
		margin-left: 0;
	}

	h3 {
		margin-bottom: 0;
		color: color(black);
	}

	span {
		@include textcompactsize;
		margin-bottom: 0;
	}
}



//
// Headline + Text Item Wrapper
//
.image-highlighter__text {
	display: block;
	position: relative;
	margin-bottom: 2vw;
	padding: .5vw .5vw .5vw 50px;
	transition: color .45s cubic-bezier(.23, 1, .32, 1);

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 10px;
		background-color: #ec0015;
		z-index: -1;
		transition: width .45s cubic-bezier(.23, 1, .32, 1);
		;
	}

	&::after {
		@include font-family(headblack);
		position: absolute;
		top: .8vw;
		left: 19px;
		content: attr(data-number);
		display: block;
		width: 20px;
		height: 20px;
		font-size: 14px;
		line-height: 1.5;
		text-align: center;
		color: inherit;
		border: 2px solid currentColor;
		border-radius: 4px;

		@include breakpoint(mid) {
			width: 24px;
			height: 24px;
			font-size: 16px;
		}
	}

	&.hover,
	&:hover,
	&:focus {

		&,
		* {
			color: #fff;
		}

		&::before {
			width: 100%;
		}
	}
}



//
// Link-Button
//
.linkbutton {

	.image-highlighter__text.hover &,
	.image-highlighter__text:focus &,
	.image-highlighter__text:hover & {
		color: #fff;

		&::after {
			background-position: left 12px top 2px;
			@include background-svg('<svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#fff" stroke-width="1.333" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>');
		}
	}
}



//
// Map Marker Icons
//
.image-highlighter__marker {
	@include font-family(headblack);
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	font-size: 14px;
	line-height: 1.5;
	text-align: center;
	color: color(black);
	background-color: color(white);
	border: 2px solid color(black);
	border-radius: 4px;
	transition: $transition;
	z-index: 5;

	&.hover,
	&:focus,
	&:hover {
		border-color: color(red500);
		color: color(white);
		background-color: color(red500);
		box-shadow: $box-shadow--1;
	}

	@include breakpoint(mid) {
		width: 24px;
		height: 24px;
		font-size: 16px;
	}
}
