
// Override glightbox styles
.glightbox-container .gcontainer {
	@media (min-width: 1101px) {
		// Make sure, slider buttons are not on top of the image
		padding: 0 80px;
	}
}
.glightbox-container .goverlay,
.glightbox-mobile .goverlay,
.goverlay {
  background-color: rgba(255, 255, 255, 0.9);
}

// .gslider is now a list, so we need to remove list padding
.glightbox-container .gslider {
	padding: 0;
}

.gslide-media {
  border-radius: $border-radius;
  box-shadow: $shadow-default;
}

.glightbox-clean .gslide-description {
  background: none;

  .gdesc-inner {
    @include font-family(sansregular !important);

    .gslide-title {
      margin-bottom: 1vw;
    }
  }
}

.glightbox-mobile .glightbox-container {
  .gslide-description {
    background: none !important;
  }
  .gslide-title,
  .gslide-desc {
    color: color(black);
  }
}

.glightbox-clean .gclose,
.glightbox-clean .gnext,
.glightbox-clean .gprev {
  background-color: transparent !important;

  path {
    fill: color(black) !important;
  }

  &:hover {
    path {
      fill: color(red500) !important;
    }
  }
}

@media (max-width: 1100px) {
  .glightbox-clean .gnext,
  .glightbox-clean .gprev {
    display: none;
  }
}

.glightbox-button-hidden {
	display: none;
}
