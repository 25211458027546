//*******************************************************************************
// HEADER
//*******************************************************************************
// TODO: refactor this to improve dev experience. there are too many styles overwriting each other from original project
.header-hero {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 4vw;
  padding-left: 5vw;
  padding-right: 5vw;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  &--subpage {
    margin-bottom: 0;
  }

  // topbar above main header area
  &__topbar {
    display: none;

    background: color(white);
    @include breakpoint(mid) {
      display: block;
      position: relative;
      z-index: 2;
    }
  }

  &__topbar-list {
    margin: 0;
    display: flex;
    height: 50px;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__topbar-item {
    margin: 0 1vw;
    color: color(coolgray200);
    padding: 0;
    list-style: none;
    line-height: 1;

    &--globe,
    &--lang {
      margin: 0;
      img {
        margin-right: 4px;
        min-height: 20px;
        height: 20px;
      }
    }
  }

  &__topbar-link {
    display: block;
    padding: 0 5px;
    font-size: 13px;
    font-weight: 400;
    color: color(coolgray500);
    border: none;
    &.is-active {
      color: color(red500);
    }

    &--lang {
      color: color(coolgray200);
      padding-left: 4px;
      padding-right: 4px;
      text-transform: uppercase;

      &.active {
        color: color(black);
      }

      &.disabled {
        cursor: not-allowed;
        color: color(coolgray100);
      }
    }
  }
}

.header-hero__logo {
  display: flex;
  align-items: center;
  height: auto;
  z-index: 200;
  width: 13vw;
  min-width: 140px;
  max-width: 200px;
  margin-right: 1rem;
  border: none;

  .header-hero__db-netze-logo {
    min-height: 20px;
    max-height: 50px;
    margin-left: 0;

    &--large {
      max-height: none;
    }
  }
}

.header-hero__subnavigation {
  background-color: #fbfbfb;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 170px;
  padding-right: 50px;
}

.header-hero--subpage {
  align-items: center;
  margin: 0 auto;
  padding: 16px 24px;
  border-bottom: 1px solid #eee;

  .header-hero__logo {
    height: 32px;

    .header-hero__db-netze-logo {
      max-height: 32px;
    }
  }

  .showmenu__label {
    margin-top: -10px;
  }

  .header-hero__navigation {
    .menu__item {
      @include breakpoint(mid) {
        &:not(:last-child) {
          margin: 0 1vw 0 0;
        }
      }

      .menu__link {
        padding-bottom: 2px;

        @include breakpoint(max) {
          font-size: 18px;
        }
      }

      .menu--search {
        padding-top: 6px;

        &--mobile {
          padding-top: 0;
        }
      }
    }
  }
}

.header-hero__navigation {
  .menu--search--desktop {
    display: none;
    @include breakpoint(lg) {
      display: block;
    }
  }

  .menu--search--mobile {
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: color(red500);
    color: color(white);
    padding: 13px;
    display: inline-block;
    @include font-family(regular);
    margin-left: 16px;
    margin-top: 8px;

    @include breakpoint(lg) {
      display: none;
    }

    &.btn--hover,
    &.btn--pressed,
    &:hover,
    &:focus {
      color: color(white);
      background-color: color(red500);
      border-color: transparent;
      text-decoration: none;

      &::after {
        transform: translateY(25%) scale(0.9);
      }
    }

    &::after {
      content: "";
      display: inline-block;
      height: 1.5em;
      width: 1.5em;
      margin-left: 0.5em;
      @include background-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"><path d="M9.17.6a8.56 8.56 0 0 1 6.64 13.98l8.55 8.55c.16.16.24.4.24.61 0 .51-.38.86-.86.86a.82.82 0 0 1-.61-.24l-8.55-8.55A8.56 8.56 0 1 1 9.17.6Zm0 1.71a6.87 6.87 0 0 0-6.86 6.86 6.87 6.87 0 0 0 6.86 6.86 6.87 6.87 0 0 0 6.86-6.86 6.87 6.87 0 0 0-6.86-6.86Z" fill="#fff" fill-rule="evenodd"/></svg>'
      );
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      transition: $transition;
      transform: translateY(25%) scale(0.8);
      transform-origin: center;
    }
  }
}

// this class is set by default to the header element when no other spacer class is set
.header-hero--spacer-big {
  margin-bottom: 20vw;
  @include breakpoint(mid) {
    margin-bottom: 11vw;
  }
}

.header-hero--large {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;

  @include breakpoint(mid) {
    border-bottom: 1px solid color(coolgray200);
  }

  // header main with logo and menu
  .header-hero__main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    position: relative;
    z-index: 1;

    &.js-showmenu--open {
      padding-top: 24px;
      padding-right: calc(5% + 12px);
      padding-left: calc(5% + 12px);
      background-color: color(white);

      // undo styles of class for bigger screens
      @include breakpoint(mid) {
        background-color: transparent;
        padding-top: 0;
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    &:not(.js-showmenu--open) {
      width: 90%;
      margin-top: 24px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 5px;
      box-shadow: $box-shadow--1;
      background-color: #fff;

      @include breakpoint(mid) {
        width: 100%;
        margin: 0;
        border-radius: 0px;
        padding: 0 24px;
        box-shadow: none;
        background-color: inherit;
      }
    }
  }

  .header-hero__logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1vw 0;
    margin-top: auto;
    @include breakpoint(sm) {
      flex-direction: row;
    }
  }
}

.header-hero--transparent {
  background: transparent;

  .header-hero__topbar {
    background: transparent;
    order: -1;
  }
}
