.news-head {
	p {
		margin-bottom: 0;
	}

	&__tag {}

	&__date {}

	&__title {
		margin-bottom: 0;
	}

	&__shorttext {
		margin-top: calc($spacer / 2);
	}
}

.news-visual {
	width: 100%;

	img {
		width: 100%;
		border-radius: $border-radius;
	}
}
