// body class if first content element is FullSizeHero
.starts-with--fullsize-hero {
	$vertical-spacing: 80px; // scss variable for vertical spaciing

	.header-hero {
		margin-bottom: 0 !important;
	}

	.header-basic {
		margin-bottom: 4vw;
	}


	// Smal Top-Navi-Bar
	.header-hero .header-hero__topbar {
		// overwrites the default styling from _header.scss
		@media (min-width: 768px) {
			display: none;
		}

		@media (min-width: 800px){
			display: block;
			position: absolute;
			background-color: #fff;
			width: 100vw;
			top: 0;
			left: 0;
			right: 0;
			height: 32px;
			padding: 0 30px;

			.header-hero__topbar-list {
				height: 32px;
				align-items: center;
			}
		}

		@media (min-width: 1280px) {
			padding: 0 $vertical-spacing;
		}
	}


	.header-hero .header-hero__main:not(.js-showmenu--open) {
		position: absolute;
		left: 0;
		right: 0;
		height: 62px;

		// overwrites the default styling from _header.scss
		@media (min-width: 768px){
			width: calc(100vw - 60px);
			margin-left: 30px;
			margin-right: 30px;
			margin-top: 24px;
			padding: 0 24px;
			border-radius: 5px;
			background-color: #fff;
			box-shadow: $box-shadow--1;
		}

		@media (min-width:800px){
			margin-top: 62px;
			height: 80px;
		}

		@media (min-width: 1280px) {
			position: absolute;
			width: calc(100vw - (2 * $vertical-spacing));
			height: 80px;
			margin-top: 76px;
			margin-left: $vertical-spacing;
			margin-right: $vertical-spacing;
			border-radius: 8px;
			background: #fff;
		}
	}

	// mobile menu is open
	.header-hero .header-hero__main.js-showmenu--open {
		padding-top: 29px;

		.menu.menu--open {
			top: 76px;
		}
	}

	.header-hero__logo-wrapper.pt0 {
		padding: 0 !important;
		margin-top: unset;
	}


	@media (min-width: 800px){
		.header-hero .submenu::after {
				border-radius: 100%;
		}
	}


	// FullSizeHero Section in <main>
	.fullsize-hero {
		margin-left: 0;
		margin-right: 0;

		&__wrapper {
			position: relative;
			width: 100vw;
			min-height: 100vh;
			display: flex;
			align-items: flex-end;

			// @media (min-width: $breakpoint){
			// 	padding-bottom:$vertical-spacing;
			// }
		}

		&__img {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			filter: brightness(.95);
		}

		// Only render in portrait mode
		@media (orientation: portrait) {
			&__img--portrait {
				display: block;
			}
		}

		@media (orientation: landscape) {
			&__img--landscape {
				display: block;
			}
		}

		.section {
			background-color: #fff;
			border-radius: 8px;
			padding: 16px;
			margin-left: 5vw;
			margin-right: 5vw;
			margin-bottom: 5vw;
			max-width: 820px;

			@media (min-width: 768px) {
				margin-left: 30px;
				margin-right: 30px;
				margin-bottom: 30px;
				padding: 32px;
			}

			@media (min-width: 1280px) {
				margin-left: $vertical-spacing;
				margin-right: $vertical-spacing;
				margin-bottom: $vertical-spacing;
			}
		}

		.section {
			// h1 {
			// 	@include breakpoint(max) {
			// 		font-size: 64px;
			// 		line-height: 72px;
			// 	}
			// }

			// .title__preheadline {
			// 	@include breakpoint(max) {
			// 		font-size: 32px;
			// 		line-height: 40px;
			// 	}
			// }

			// .btn {
			// 	@include breakpoint(max) {
			// 		font-size: 18px;
			// 		line-height: 23px;
			// 		min-height: 40px;
			// 		max-height: 45px;
			// 	}
			// }

			h1,
			.title__preheadline {
				margin-bottom: 0;
			}

			h1 + .title__preheadline {
				margin-top: 6vw !important;

				@include breakpoint(min) {
					margin-top: 24px !important;
				}

				@include breakpoint(mid) {
					margin-top: 2vw !important;
				}

				// @include breakpoint(max) {
				// 	margin-top: 32px !important;
				// }
			}

			h1 + .btn,
			.title__preheadline + .btn {
				margin-top: 6vw !important;

				@include breakpoint(min) {
					margin-top: 24px !important;
				}

				@include breakpoint(mid) {
					margin-top: 2vw !important;
				}

				// @include breakpoint(max) {
				// 	margin-top: 32px !important;
				// }
			}
		}


		// Layout-Modifier for the small white box
		&.fullsize-hero--right {
			.fullsize-hero__wrapper {
				justify-content: flex-end;
			}
		}

	}
}
