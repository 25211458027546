
.footer {

  .list--unstyled {
    ul {
      margin-top: 0;
    }

    li {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;

      &::before {
        display: none;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    padding-left: 1vw;
    padding-right: 1vw;

    &--animation {
      lottie-player {
        z-index: -1;
        display: flex;
        margin-bottom: -5px;
        margin-bottom: clamp(-6px, -0.625vw, -4px);
      }
    }

    &--content {
      background-color: color(coolgray600);
      color: color(white);
      padding-top: 8vw;
      padding-bottom: 3.5vw;
      padding-left: 20px;
      padding-right: 20px;

      @include breakpoint(min) {
        padding-left: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
        padding-right: calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
        padding-top: 5vw;
      }

      @include breakpoint(mid) {
        padding-left: calc(10vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
        padding-right: calc(10vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
        padding-top: 4vw;
      }

      @include breakpoint(max) {
        padding-left: 15vw;
        padding-right: 15vw;
        padding-top: 3.5vw;
        padding-bottom: 3.5vw;
      }

      a {
        color: color(white);
        border-bottom: 1px solid inherit;
      }
    }

    &--bottom {
      background-color: color(coolgray100);
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__element {


    &--left {}

    &--right {}
  }

  &__navigation {
    padding-left: 0;
    margin-top: 5vw;
    margin-bottom: 5vw;

    @include breakpoint(mid) {
      margin-top: 2vw;
      margin-bottom: 2vw;
      display: flex;

      .list__item {
        &:not(:first-child) {
          margin-left: 2vw;
        }
      }
    }

    .list__item {
      font-size: 3.5vw;
      line-height: 6vw;

      &:not(:last-child) {
        margin-bottom: 1vw;

        @include breakpoint(min) {
          margin-bottom: 0;
        }
      }

      @include breakpoint(min) {
        font-size: $textfontsizemin + px;
        line-height: $textlineheightmin + px;
      }

      @include breakpoint(mid) {
        font-size: calc(#{$textsmallfontsizemin}px + (#{$textsmallfontsizemax} - #{$textsmallfontsizemin}) * ((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint})));
        line-height: calc(#{$textsmalllineheightmin}px + (#{$textsmalllineheightmax} - #{$textsmalllineheightmin}) * ((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint})));
      }

      @include breakpoint(max) {
        font-size: #{$textsmallfontsizemax}px;
        line-height: #{$textsmalllineheightmax}px;
      }
    }

    .list__item--cookies {
      margin-right: 3em;
    }


    .list__link {
      color: color(black);

      &:active,
      &:focus,
      &:hover {
        text-decoration: none;
        border-bottom: 1px solid color(black);
      }
    }
  }
}


//
// To-Top Arrow
//
.footer__totop {
  display: flex;
  align-items: center;
  font-weight: 400;
  color: color(black);
  transform: translateY(10px);
  opacity: 0;
  border-bottom: none;

  @extend .small;

  &:hover {
    color: color(red500);
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: all $transition-time $transition-function;
  }

  &.is--visible {
    transform: translateY(0%);
    opacity: 1;
  }

  &.intersectionObserverNotSupported {
    transform: translateY(0%);
    opacity: 1;
  }

  span {
    font-size: 3.5vw;
    line-height: 6vw;

    @include breakpoint(min) {
      font-size: $textfontsizemin + px;
      line-height: $textlineheightmin + px;
    }

    @include breakpoint(mid) {
      font-size: calc(#{$textsmallfontsizemin}px + (#{$textsmallfontsizemax} - #{$textsmallfontsizemin}) * ((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint})));
      line-height: calc(#{$textsmalllineheightmin}px + (#{$textsmalllineheightmax} - #{$textsmalllineheightmin}) * ((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint})));
    }

    @include breakpoint(max) {
      font-size: #{$textsmallfontsizemax}px;
      line-height: #{$textsmalllineheightmax}px;
    }
  }

  svg {
    margin-left: 12px;
    width: 20px;
    height: 20px;
    max-height: 32px;
    max-width: 32px;
    padding: 4px;
    background: color(white);
    fill: color(red500);
    border-radius: 100%;

    @include breakpoint(mid) {
      width: 24px;
      height: 24px;
    }

    @include breakpoint(max) {
      width: 2vw;
      height: 2vw;
    }

  }
}

