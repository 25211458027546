@mixin headline($fontsize: 26) {
	margin-bottom: 4vw;

	@include breakpoint(min) {
		font-size: 18px;
		line-height: 24px;
	}

	@include breakpoint(mid) {
	  font-size: calc(#{$fontsize - 6}px + (32 - 24) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(32px + (40 - 32) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: 2vw;
	}

	@include breakpoint(max) {
		font-size: 2vw;
		line-height: 2.5vw;
	}
}
