.db-neos-media__image-wrapper {
  position: relative;
  margin: 0;

	&.spacerbottom--zero {
		@extend %spacerbottom--zero
	}
	&.spacerbottom--small {
		@extend %spacerbottom--small;
	}
	&.spacerbottom--medium {
		@extend %spacerbottom--medium;
	}
	&.spacerbottom--big {
		@extend %spacerbottom--big;
	}

	&.padding--zero {
		@extend %padding--zero;
	}
	&.padding--small {
		@extend %padding--small;
	}
	&.padding--medium {
		@extend %padding--medium;
	}
	&.padding--big {
		@extend %padding--big;
	}

	&.padding-top--small {
		@extend %padding-top--small;
	}
	&.padding-top--medium {
		@extend %padding-top--medium;
	}
	&.padding-top--big {
		@extend %padding-top--big;
	}

	&.padding-bottom--small {
		@extend %padding-bottom--small;
	}
	&.padding-bottom--medium {
		@extend %padding-bottom--medium;
	}
	&.padding-bottom--big {
		@extend %padding-bottom--big;
	}

	&.spacerbottom--sm-zero {
		@extend %spacerbottom--sm-zero;
	}

	&.spacerbottom--sm-small {
		@extend %spacerbottom--sm-small;
	}

	&.spacerbottom--sm-medium {
		@extend %spacerbottom--sm-medium;
	}

	&.spacerbottom--sm-big {
		@extend %spacerbottom--sm-big;
	}

	.glightbox.focus-visible {
		box-shadow: none;

		& > img {
			outline: 2px solid color(red500);
			outline-offset: 2px;
		}
	}
}

.db-neos-media__image {
	position: relative;
}

// .db-neos-media__image-meta {}

.db-neos-media__image-item {
  max-width: none;
  width: 100%;

  &--rounded {
    border-radius: $border-radius;
  }
}

.db-neos-media__image-caption {
  color: color(coolgray500);
  margin-top: 2vw;
  margin-bottom: 0;
  padding: 0 5vw;
  font-size: 2.5vw;
  line-height: 3vw;

  @include breakpoint(min) {
    font-size: 10px;
    line-height: 12px;
  }

  @include breakpoint(mid) {
    font-size: calc(10px + (14 - 10) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
    line-height: calc(12px + (20 - 12) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
    margin-top: 1vw;
    margin-bottom: 1vw;
  }

  @include breakpoint(max) {
    font-size: .875vw;
    line-height: 1.25vw;
  }
}

.db-neos-media__image-meta {
	position: absolute;
	right: 0;
	bottom: 0;
	display: inline-block;
	color: color(coolgray800);
	background-color: color(coolgray100);
	font-size: 10px;
	line-height: 10px;
	padding: 5px 10px;
	margin: 0;
	border-top-left-radius: $border-radius;
}
