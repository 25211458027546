//*******************************************************************************
// HEADER
//*******************************************************************************

.header-basic {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto 8vw;
  padding-top: 4vw;
  padding-left: 5vw;
  padding-right: 5vw;
}

.header-basic__logo {
  display: flex;
  align-items: center;
  height: 44px;
  z-index: 200;

  img {
    width: auto;
    height: 5.5vw;
    max-width: none;
    max-height: 44px;
    min-height: 24px;
  }
}

.header-basic__subnavigation {
  background-color: #fbfbfb;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 170px;
  padding-right: 50px;
}

.header-basic__navigation {
  .menu--search--desktop {
    display: none;
    @include breakpoint(lg) {
      display: block;
    }
  }

  .menu--search--mobile {
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: color(red500);
    color: color(white);
    padding: 13px;
    display: inline-block;
    @include font-family(regular);
    margin-left: 16px;
    margin-top: 8px;

    @include breakpoint(lg) {
      display: none;
    }

    &.btn--hover,
    &.btn--pressed,
    &:hover,
    &:focus {
      color: color(white);
      background-color: color(red600);
      border-color: transparent;
      text-decoration: none;

      &::after {
        transform: translateY(25%) scale(0.9);
      }
    }

    &::after {
      content: "";
      display: inline-block;
      height: 1.5em;
      width: 1.5em;
      margin-left: 0.5em;
      @include background-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"><path d="M9.17.6a8.56 8.56 0 0 1 6.64 13.98l8.55 8.55c.16.16.24.4.24.61 0 .51-.38.86-.86.86a.82.82 0 0 1-.61-.24l-8.55-8.55A8.56 8.56 0 1 1 9.17.6Zm0 1.71a6.87 6.87 0 0 0-6.86 6.86 6.87 6.87 0 0 0 6.86 6.86 6.87 6.87 0 0 0 6.86-6.86 6.87 6.87 0 0 0-6.86-6.86Z" fill="#fff" fill-rule="evenodd"/></svg>'
      );
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      transition: $transition;
      transform: translateY(25%) scale(0.8);
      transform-origin: center;
    }
  }
}
