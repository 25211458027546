.db-timeline__milestone {
	opacity: 0;
	display: flex;
	justify-content: center;
	padding: 20px;
	margin-bottom: 20px;

	@include breakpoint(mid) {
		padding: 0 1.5vw;
		margin-bottom: 40px;
	}

	@include breakpoint(max) {
		padding: 0 1vw;
	}
}

.db-timeline__milestone-inner {
	background-color: color(red500);
	color: color(white);
	text-align: center;
	padding: 6px 12px;
	border-radius: $border-radius;
}

.db-timeline__milestone-headline {
	line-height: 1.2;
	margin-bottom: 0;
}

.db-timeline__milestone-content {
	p {
		line-height: 1.2;
		margin-bottom: 0;
	}
}

.db-timeline + .db-timeline__milestone {
	margin-top: -60px;

	@include breakpoint(mid) {
		margin-top: 0;
	}
}

.db-timeline__milestone + .db-timeline .db-timeline__branch:after {
	display: none;

	@include breakpoint(mid) {
		display: block;
	}
}
