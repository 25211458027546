/*
	DB.Neos.Hero101 / Main.scss

	Diese Datei enthält alle Styles, die für die im Paket enthaltenen 
	Module benötigt werden. Hier können Variablen und Vererbungen 
	aus dem DB.Neos.Base Paket verwendet werden. 
	
	Projektspezifische Änderungen an den Komponenten dieses Paketes 
	finden im Projekt-Paket (z.B. DB.Hochwasser) statt.
*/

.hero101 {
  position: relative;

  &__headline {
    margin: 0 5vw;
    color: color(black);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-bottom: 0;

    @include breakpoint($breakpoint-sm) {
      margin: 0;
      color: color(white);
      position: absolute;
      left: 25vw;
      right: 25vw;
      top: 50%;
      z-index: 1;
      transform: translateY(-50%);
    }

    &::after {
      content: "";
      width: 40px;
      height: 4px;
      border-radius: $border-radius;
      background-color: color(red500);
      margin-top: 6px;

      @include breakpoint($breakpoint-mid) {
        width: calc(#{40}px + (#{80} - #{40}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
        height: calc(#{4}px + (#{10} - #{4}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
        margin-top: 1.25vw;
      }

      @include breakpoint($breakpoint-max) {
        width: 5vw;
        height: 0.625vw;
      }
    }
  }

  &__image-wrapper {
    margin-top: 40px;
    position: relative;

    @include breakpoint($breakpoint-sm) {
      margin: 0 5vw;
      border-radius: $border-radius;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: color(black);
        opacity: 0.3;
      }
    }
  }

  &__image {
    width: 100%;
    aspect-ratio: 400 / 226;
    object-fit: cover;

    @include breakpoint($breakpoint-mid) {
      aspect-ratio: 1440 / 600;
    }
  }
}

.hero101__css-missing {
  display: none;
}