.dev-message {
    display: block;
    border: 2px solid #ccc;
    background: #eee;
    padding: 4em;
    color: #333;
    font-size: .5em;
    line-height: 1.3;
    border-radius: 15px;
    margin: 4em;
}

.dev-message * {
    color: #333;
}

.dev-message *:first-child {
    margin-top: 0;
}

.dev-message *:last-child {
    margin-bottom: 0;
}

.dev-message pre {
    display: inline-block;
    color: #333;
    font-size: 2em;
    line-height: 1.4;
    background: #ddd;
    padding: .7em 1.5em;
}