/*
  DB.Neos.CallToActionSection / Main.scss

  Diese Datei enthält alle Styles, die für die im Paket enthaltenen
  Module benötigt werden. Hier können Variablen und Vererbungen
  aus dem DB.Neos.Base Paket verwendet werden.

  Projektspezifische Änderungen an den Komponenten dieses Paketes
  finden im Projekt-Paket (z.B. DB.Hochwasser) statt.
*/

.calltoactionsection {
  display: block;
  border: 2px solid #ccc;
  background: #eee;
  padding: 4em;
  color: #333;
  font-size: .5em;
  line-height: 1.3;
  border-radius: 15px;
  margin: 4em;
}

.calltoactionsection__css-missing {
  display: none;
}

.section--has-background.background-color--red500.db-call-to-action-section {
  .btn {
    &--negative {
      color: color(black);
    }
  }
}

