
/* TODO:: VORÜBERGEHEND! => wenn mehr INhalte da sind wieder einblenden */
.accordion__filter{
    display:none !important;
}

.accordion__filter__tag {
    @include placeholder{ color: color(coolgray300); }
    display: block;
    margin-right: 3em;
    width: 100%;
    max-width: 400px;
    margin-bottom: 3em;
    height: 40px;
    padding: 5px 20px;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: color(coolgray100);
    color: color(black);

    @include breakpoint(mid) { 
        @include bodytextsize;
        height: 56px;
        margin-left: -20px;
    }

    @include breakpoint(max) {
        height: 72px;
    }

    &:focus {
        outline: none;
        border: 1px solid color(coolgray300);
    }

    &.is-invalid {
        border: 1px solid color(errorred);

        &:focus {
            border: 1px solid color(coolgray300);
        }
    }

    &:-internal-autofill-selected {
        border: 1px solid color(coolgray300);
    }
    
    appearance: none;
    position: relative;
    background-size: 20px;
    background-position: right 20px center;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHBvbHlsaW5lIHRyYW5zZm9ybT0icm90YXRlKDkwLDYsNikiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzRENEQ2MCIgcG9pbnRzPSIzLjcgMS44IDcuOSA2IDMuNyAxMC4yIi8+PC9zdmc+);
    cursor: pointer;
}