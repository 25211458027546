.interactive-map .header {
	padding-top: 25px;
	padding-bottom: 25px;
	height: 90px;
	margin-bottom: 0;
	position: relative;
	z-index: 999;
}

.fullwidthmap {
	position: relative;
	height: calc(100vh - 90px);

	#map {
		position: relative;
		z-index: 1;
		height: 100%;
	}
	.marker{
		z-index: 1;
	}

	.marker:hover {
		cursor: pointer;
	}

	.mapboxgl-marker,
	.maplibregl-marker {
		background-size: 100%;
	}

	#mapswitch {
		position: absolute;
		left: 10px;
		bottom: 102px;
		border-radius: 7px;
		border: 3px solid white;
		z-index: 100;
		padding: 0;

		&:hover {
			cursor: pointer;
		}

		img {
			display: none;
			width: 25px;
			height: 25px;
			border-radius: 3px;
			min-height: 0;
		}

		&.map-flat #flat {
			display: block;
		}

		&.map-satellite #satellite {
			display: block;
		}
	}
}