.db-timeline__wrapper {
  position: relative;
}

.db-timeline {
  position: relative;
  display: flex;
  margin-top: 80px;
  margin-bottom: 80px;
  min-height: 0;
  opacity: 0;

  @include breakpoint(mid) {
    margin-top: 0;
    margin-bottom: 40px;
  }

  & ~ .db-timeline {
    margin-top: 0;
  }

  &.db-timeline--odd {
    @include breakpoint(mid) {
    }
  }

  &.db-timeline--even {
    @include breakpoint(mid) {
      justify-content: flex-end;
    }
  }
}

.db-timeline__inner {
  width: 100%;
  height: fit-content;
  border-radius: $border-radius;
  background-color: color(coolgray100);
  transition: all 0.2s $transition-function;

  @include breakpoint(mid) {
    width: calc(50% - 5vw);
  }

  @include breakpoint(max) {
    .db-timeline--odd & {
      margin-left: -1vw;
      width: calc(45% - 4vw);
    }

    .db-timeline--even & {
      margin-right: -1vw;
      width: calc(45% - 4vw);
    }
  }

  &:hover,
  &:focus,
  &.db-timeline--open {
    box-shadow: $box-shadow--1;
    background-color: color(white);
  }
}

.db-timeline__title {
  @include font-family(sansregular);
  @include bodytextsize;
  margin-bottom: 0;

  @include breakpoint(mid) {
    margin-bottom: 0;
  }

  .db-timeline--open & {
    @include font-family(headblack);
  }
}
$altbg: #002b59;

// alt background case
.db-timeline__wrapper--alt-background {
  .db-timeline__inner {
    background-color: $altbg;
    color: #fff;

    &.db-timeline--open {
      background-color: #fff;
      color: $altbg;
      border: 3px solid $altbg;

      .db-timeline__button {
        color: #000;
      }

      .db-timeline__toggleicon {
      }
    }
  }
  // not pretty:
  .db-timeline__toggleicon {
    @include background-svg(
      '<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 11c.562 0 1 .406 1 1 0 .25-.094.5-.281.719l-8 8a1.101 1.101 0 01-.719.28c-.25 0-.5-.093-.719-.28l-8-8a1.101 1.101 0 01-.28-.719c0-.25.093-.5.28-.719a1.1 1.1 0 01.72-.28c.25 0 .5.093.718.28L16 18.594l7.281-7.313C23.5 11.094 23.75 11 24 11z" fill="#fff" fill-rule="nonzero"/></svg>'
    );
  }
  .db-timeline__button:focus .db-timeline__toggleicon,
  .db-timeline__button:hover .db-timeline__toggleicon {
    @include background-svg(
      '<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 11c.562 0 1 .406 1 1 0 .25-.094.5-.281.719l-8 8a1.101 1.101 0 01-.719.28c-.25 0-.5-.093-.719-.28l-8-8a1.101 1.101 0 01-.28-.719c0-.25.093-.5.28-.719a1.1 1.1 0 01.72-.28c.25 0 .5.093.718.28L16 18.594l7.281-7.313C23.5 11.094 23.75 11 24 11z" fill="#fff" fill-rule="nonzero"/></svg>'
    );
  }

  .db-timeline__button[aria-expanded="true"] .db-timeline__toggleicon {
    @include background-svg(
      '<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 21c.562 0 1-.407 1-1 0-.25-.094-.5-.281-.719l-8-8A1.101 1.101 0 0016 11c-.25 0-.5.094-.719.281l-8 8a1.101 1.101 0 00-.28.719c0 .25.093.5.28.718a1.1 1.1 0 00.72.282c.25 0 .5-.094.718-.282L16 13.406l7.281 7.312c.219.188.469.282.719.282z" fill="#002b59" fill-rule="nonzero"/></svg>'
    );
  }

  .db-timeline__button[aria-expanded="true"]:focus .db-timeline__toggleicon,
  .db-timeline__button[aria-expanded="true"]:hover .db-timeline__toggleicon {
    @include background-svg(
      '<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 21c.562 0 1-.407 1-1 0-.25-.094-.5-.281-.719l-8-8A1.101 1.101 0 0016 11c-.25 0-.5.094-.719.281l-8 8a1.101 1.101 0 00-.28.719c0 .25.093.5.28.718a1.1 1.1 0 00.72.282c.25 0 .5-.094.718-.282L16 13.406l7.281 7.312c.219.188.469.282.719.282z" fill="#002b59" fill-rule="nonzero"/></svg>'
    );
  }

  .db-timeline__title,
  .db-timeline__button {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

	.db-timeline__milestone-inner, .db-timeline__branch, .db-timeline__verticalline {
		background-color: $altbg;
	}

	.db-timeline__branch::after {
		border: 3px solid $altbg;
	}
	.db-timeline--active .db-timeline__branch::after {
			background-color: $altbg;
	}
	.db-timeline--finished .db-timeline__branch {
		&::after {
			background-color: $altbg;
			@include background-svg('<svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M.333 7.44c0-.435.374-.75.773-.75.3 0 .374.05.548.219l4.088 3.943L15.987.884a.726.726 0 01.549-.217c.448 0 .797.387.797.75 0 .193-.074.363-.249.532l-10.793 10.5a.786.786 0 01-.549.218.726.726 0 01-.548-.218L.558 7.973a.74.74 0 01-.225-.532z" fill="#fff" fill-rule="nonzero"/></svg>');
		}
	}
}
