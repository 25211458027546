//*******************************************************************************
//
// This file contains all @font-face declarations, if any.
//
//*******************************************************************************

//
// Head Light
//
//
@font-face {
	font-family: "DBHead-Light";
	font-display: swap;
	src: url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBHead-Light/DBHead-Light.eot');
	src: url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBHead-Light/DBHead-Light.eot?#iefix') format('embedded-opentype'),
		 url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBHead-Light/DBHead-Light.woff2') format('woff2'),
		 url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBHead-Light/DBHead-Light.woff') format('woff'),
		 url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBHead-Light/DBHead-Light.ttf') format('truetype'),
		 url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBHead-Light/DBHead-Light.svg#DBHead-Light') format('svg');
}


//
// Head Black
//
@font-face {
	font-family: "DBHead-Black";
	font-display: swap;
	src: url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBHead-Black/DBHead-Black.eot');
	src: url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBHead-Black/DBHead-Black.woff2') format('woff2'),
		 url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBHead-Black/DBHead-Black.woff') format('woff'),
		 url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBHead-Black/DBHead-Black.ttf') format('truetype'),
		 url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBHead-Black/DBHead-Black.svg#DBHead-Black') format('svg');
}


//
// Sans Bold
//
@font-face {
	font-family: "DBSans-Bold";
	font-display: swap;
	src: url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBSans-Bold/DBSans-Bold.eot');
	src: url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBSans-Bold/DBSans-Bold.woff2') format('woff2'),
		 url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBSans-Bold/DBSans-Bold.woff') format('woff'),
		 url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBSans-Bold/DBSans-Bold.ttf') format('truetype'),
		 url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBSans-Bold/DBSans-Bold.svg#DBSans-Bold') format('svg');
}



//
// Sans Regular
//
@font-face {
	font-family: "DBSans-Regular";
	font-display: swap;
	src: url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBSans-Regular/DBSans-Regular.eot');
	src: url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBSans-Regular/DBSans-Regular.woff2') format('woff2'),
		 url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBSans-Regular/DBSans-Regular.woff') format('woff'),
		 url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBSans-Regular/DBSans-Regular.ttf') format('truetype'),
		 url('/_Resources/Static/Packages/DB.Neos.Base/fonts/DBSans-Regular/DBSans-Regular.svg#DBSans-Regular') format('svg');
}



//
// Font Family Mixin
// usage: @include font-family(sansbold);
//
 @mixin font-family($value: regular) {
	@if $value == headlight {
		font-family: "DBHead-Light", "Arial", "Helvetica", sans-serif;
		font-style: normal;
		font-weight: normal;
	}
	@else if $value == headblack {
		font-family: "DBHead-Black", "Arial", "Helvetica", sans-serif;
		font-style: normal;
		font-weight: normal;
	}
	@else if $value == sansbold {
		font-family: "DBSans-Bold", "Arial", "Helvetica", sans-serif;
		font-style: normal;
		font-weight: normal;
	}
	@else  {
		font-family: "DBSans-Regular", "Arial", "Helvetica", sans-serif;
		font-style: normal;
		font-weight: normal;
	}
}
