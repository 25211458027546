/*
	DB.Neos.FooterBasic / Main.scss

	Diese Datei enthält alle Styles, die für die im Paket enthaltenen
	Module benötigt werden. Hier können Variablen und Vererbungen
	aus dem DB.Neos.Base Paket verwendet werden.

	Projektspezifische Änderungen an den Komponenten dieses Paketes
	finden im Projekt-Paket (z.B. DB.Hochwasser) statt.
*/

//*******************************************************************************
// FOOTER
//*******************************************************************************

@import 'footer';
@import 'footer-image';

html.msie {
	.footer__row--animation {
		height: 20%;

		@include breakpoint(mid) {
			height: 25%;
		}

		@include breakpoint(max) {
			height: 30%;
		}
	}
}



//
// dummy Element to trigger the to-top-link
//
.pixeltowatch {
	position: absolute;
	width: 1px;
	height: 1px;
	top: 200vh; // position to trigger the to-top-link
	top: m#{i}n(100%, 200vh); // Fix position on short pages. m#{i}n as fix to not use Sass-Function here.
	right: 0;
}
