.db-timeline__media {
	@include breakpoint(mid) {
		position: absolute;
		top: 0;
		right: 0;
		width: calc(50% - 5vw);

		.db-timeline--even & {
			right: auto;
			left: 0;
		}
	}

	@include breakpoint(max) {
		.db-timeline--odd & {
			width: calc(45% - 4vw);
			margin-right: -1vw;
		}

		.db-timeline--even & {
			width: calc(45% - 4vw);
			margin-left: -1vw;
		}
	}

	.image {
		margin-bottom: 8px;

		@include breakpoint(mid) {
			opacity: 0;
			transition: opacity .1s $transition-function;
		}
	}

	.image__desc {
		padding-left: 0;
		padding-right: 0;
	}

}
