.db-neos-media__pictogram {
}

.db-neos-media__pictogram-container {
  &--large {
    width: 300px;
  }
  &--small {
    width: 150px;
  }
}

.db-neos-media__pictogram-image {
  width: 100%;
}
