.fullwidthmap {

  .swiper {
    max-width: 1200px;
  }
  
   .swiper-slide {
    max-width: 1200px !important;
  }
  
  .slide-content-wrapper{
    max-height: 86vh;
    overflow-y: overlay;
  }

  .slide-content {
    max-width: 500px;
    color: black;
    background-color: white;
    border-radius: 7px;
    overflow: hidden;

    &.scrollable{
      margin-right: 16px;
    }
  
    .slide-text {
      padding: 40px 20px 30px;
    }
  
    .pre-headline {
      margin-bottom: 1em;
    }
  
    h4 {
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 1.5rem;
      line-height: 1;
    }
  
    p {
      margin: 0;
      line-height: 1.5;
    }
  }
  
  .swiper-button-next,
  .swiper-button-prev {
    color: white;
    display: none;
    
    @media (min-width: 600px) {
      display: flex;
    }
  }
  
  .slide-container {
    color: white;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
  }
  
  .slide-before,
  .slide-next {
    padding: 0 16px;
    z-index: 200;
    width: 260px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    display: none;
  
    @media (min-width: 1024px) {
      display: block;
    }
  }
  
  .swiper-slide-active {
    .slide-before,
    .slide-next {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }
  }
  
  .slide-before {
    text-align: right;
  }

  .swiper-wrapper {
    align-items: center;
  }
  
  .swiper-fade {
    .swiper-slide {
      opacity: 0 !important;
      transition-duration: 0.2s !important;
    }
  
    .swiper-slide-active {
      opacity: 1 !important;
    }
  }
}
  