#layer-button {
  font-size: 16px;
  position: absolute;
  right: 10px;
  bottom: 40px;
  z-index: 100;

  background-color: white;
  border: 0;
  padding: 5px 10px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 768px) {
    bottom: 30px;
  }

  img {
    width: 32px;
    height: 30px;
  }
}

#layer-menu {
  position: absolute;
  right: 10px;
  bottom: 105px;

  background-color: white;
  border: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  z-index: 1001;
  width: 250px;
  padding-bottom: 10px;
  padding-top: 5px;

  @media (min-width: 768px) {
    bottom: 95px;
  }

  transform: scale(0) rotate(-10deg);
  transition: transform 0.2s cubic-bezier(0, 0, 0.27, 1);
  transform-origin: right bottom;

  &.open {
    transform: scale(1) rotate(0);
    transition: transform 0.3s cubic-bezier(0.68, 0, 0.27, 1.55);
  }

  &::after {
    top: 100%;
    right: 10px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 15px;
    margin-left: -15px;
  }

  h3 {
    margin-top: 5px;
    margin-bottom: 0;
    padding: 10px 15px;
    line-height: 1;
    font-size: 19px;
  }

  .form {
    width: 100%;
    padding: 7px 15px 7px 50px;
    overflow: hidden;
    line-height: normal;
    font-size: 16px;
    display: none;

    &[data-layer='route'],
    &.visible {
      display: block;
    }

    &:hover {
      background: #f0f3f5;
    }

    padding-left: 50px;

    div::before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
			position: absolute;
      top: 12px;
      left: -35px;
      background-repeat: no-repeat;
			background-image: url("data:image/svg+xml,%3Csvg width='21' height='18' viewBox='0 0 21 18' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M18.93 0c.532 0 1 .438 1 1 0 .25-.093.531-.28.719l-16 16a1.101 1.101 0 0 1-.72.281c-.562 0-1-.406-1-1 0-.25.094-.5.282-.688l2.718-2.718c-1.406-1.188-2.687-2.5-3.78-3.969A.955.955 0 0 1 .93 9c0-.219.063-.438.22-.625C2.804 6.312 6.242 2 10.93 2a8.864 8.864 0 0 1 4.344 1.25L18.212.312A.998.998 0 0 1 18.93 0Zm-8 4C7.587 4 4.805 7.063 3.212 9a19.903 19.903 0 0 0 3.125 3.156l2.375-2.344c-.125-.25-.157-.53-.157-.812a2.374 2.374 0 0 1 2.375-2.375c.282 0 .563.063.813.156l2.031-2.062A6.833 6.833 0 0 0 10.93 4Zm6.063 3.156A26.581 26.581 0 0 1 18.649 9c-1.594 1.969-4.375 5-7.719 5-.25 0-.468 0-.718-.031l-1.657 1.656c.75.25 1.563.375 2.375.375 4.688 0 8.125-4.313 9.782-6.375A.955.955 0 0 0 20.93 9a.955.955 0 0 0-.218-.625A36.115 36.115 0 0 0 18.43 5.75l-1.437 1.406Z' fill='%23282D37' fill-rule='nonzero' opacity='.2'/%3E %3C/svg%3E");
    }

    &:hover div::before {
      display: block;
      opacity: 1;
      background-repeat: no-repeat;
			background-image: url("data:image/svg+xml,%3Csvg width='21' height='14' viewBox='0 0 21 14' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M10.167 0c3.687 0 6.406 2.188 9.78 6.375a.955.955 0 0 1 .22.625.955.955 0 0 1-.22.625C16.574 11.844 13.855 14 10.168 14c-3.47 0-5.938-1.688-9.782-6.375A.955.955 0 0 1 .167 7c0-.219.062-.438.218-.625C3.76 2.187 6.48 0 10.167 0Zm0 2c-2.532 0-4.688 1.406-7.72 5 2.938 3.5 4.97 4.906 7.72 5 2.53 0 4.687-1.406 7.718-5-2.937-3.5-4.968-4.906-7.718-5Zm0 2a3 3 0 1 1-.002 6.002A3 3 0 0 1 10.167 4Zm0 2c-.563 0-1 .469-1 1 0 .563.437 1.031 1 1.031.562 0 1-.469 1-1.031 0-.531-.438-1-1-1Z' fill='%23282D37' fill-rule='nonzero'/%3E %3C/svg%3E");
    }
  }

  input {
    display: none;
  }

  label {
    margin-bottom: 0;

    div {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 16px;
    }

    input + div {
      img,
      span {
        opacity: 0.5;
      }
    }

    input:checked + div {
      img,
      span {
        opacity: 1;
      }

      &::before {
        display: none;
      }
    }

    img {
      margin-right: 10px;
      width: 35px;
      height: 35px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.hide-trainstation [data-type="trainstation"],
.hide-tunnel [data-type="tunnel"],
.hide-viaduct [data-type="viaduct"],
.hide-energy [data-type="energy"],
.hide-engineering-structures [data-type="engineering-structures"],
.hide-environment [data-type="environment"],
.hide-transferpoint [data-type="transferpoint"],
.hide-commercial [data-type="commercial"],
.hide-hillside [data-type="hillside"],
.hide-bridge [data-type="bridge"],
.hide-pedestrian-crossing [data-type="pedestrian-crossing"],
.hide-retaining-wall [data-type="retaining-wall"],
.hide-railroad-crossing [data-type="railroad-crossing"] {
  display: none;
}

