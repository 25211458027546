$container-width: 300px;
$container-height: $container-width;
$media-height: 140px;

.db-dynamicnumbers {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    @include breakpoint(sl){
      flex-direction: row;
      height: $container-height;
    }
  }

  &__info {
    height: 300px;
    background: white;
    margin: 0 20em;
    padding: 3em;
  }

  &__warning {
    text-align: center;
    background: color(orange500) + cf; // add opacity to the calculated color
    border: 2px darken(color(orange500), 10%) solid;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__item-wrapper {
    flex: 1;
    text-align: center;
    margin: 2vw 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    max-width: calc($container-width - 100px);

    @include breakpoint(sl){
      max-width: $container-width;
      justify-content: start;
    }
  }

  &__item-text {
    @include font-family(sansbold);
    line-height: 1.3em;
    margin: 0 25px;
  }

  &__item-number, &__item-media {

    height: $media-height;

    @include font-family(sansbold);

    font-size: 2em;
    margin: 0.5em 0;

    // start red line
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 5px;
    align-items: end;
    gap: 0.3em;

    text-align: center;

    &::after {
      background-color: color(red500);
      border-radius: 6px;
      content: '';
      width: 2ex;
      height: 5px;
      display: block;
      margin: 0 auto;
    }
    // -- end red line
  }

  &__item-media {
    img {
      display: block;
      max-width: 300px;
      max-height: $media-height;
      min-height: 70px;
      width: auto;
      height: auto;
    }
  }
}
