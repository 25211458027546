.section--booking-cal {
	margin-left: 5vw;
	margin-right: 5vw;

	@include breakpoint(mid) {
		margin-left: calc(20vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
		margin-right: calc(20vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}
	@include breakpoint(max) {
		margin-left: 20vw;
		margin-right: 20vw;
	}
	@include breakpoint(1920) {
		margin-left: 25vw;
		margin-right: 25vw;
	}

	&::after {
		content: '';
		clear: both;
		display: block;
	}
}

.booking__holidays,
.booking__kw {
	border-top: 1px solid color(warmgray500);
	padding-top: 2.5vw;
}

.booking__holidays {
	margin-bottom: 2.5vw;
}

.booking__kw {
	margin-bottom: 0;
}

.booking-cal {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 -20px;
}

.booking-cal__wrapper {
	margin-bottom: 40px;
	padding: 0 20px;

	@include breakpoint(0, 1599px) {
		&:nth-child(n + 3) {
			display: none;
		}
	}
}

.booking-cal__headline {
	text-align: center;
	margin-bottom: 0;
}

.booking-cal__day-head,
.booking-cal__day-np,
.booking-cal__day {
	text-align: center;
	user-select: none;
	width: 34px;
	height: 34px;

	@include breakpoint(lg) {
		width: 40px;
		height: 40px;
	}
}

.booking-cal__day-number {
	line-height: 32px;

	@include breakpoint(lg) {
		line-height: 38px;
	}

	&--green,
	&--orange,
	&--red {
		&:hover {
			cursor: pointer;
		}
	}

	&--green {
		background-color: color(green500);
		color: #fff;
		cursor: pointer;
	}
	&--orange {
		background-color: color(orange500);
		color: #fff;
		cursor: pointer;
	}
	&--red {
		background-color: color(red500);
		color: #fff;
		cursor: pointer;
	}
}

.booking-cal__day-number--holiday {
	background-color: #eee;
	color: color(warmgray500);
}

.booking-cal__legendlist,
.booking-cal__events {
	list-style-type: none;
	padding: 0;
}

.booking-cal__events:empty::before {
	content: 'Für den ausgewählten Tag sind keine freien Termine mehr vorhanden.';
	font-weight: bold;
}

@include breakpoint(sm) {
	.booking-cal__legend {
		float: right;
		margin-left: 40px;
	}
}

.booking-cal__legenditem {
	display: flex;
	font-size: 10px;
	line-height: 1;
	margin-bottom: 2px;
	align-items: center;
}


.booking-cal__legend-green,
.booking-cal__legend-orange,
.booking-cal__legend-red {
	display: inline-block;
	margin-right: 5px;
	width: 20px;
	height: 20px;
}

.booking-cal__legend-green {
	background-color: color(green500);
}
.booking-cal__legend-orange {
	background-color: color(orange500);
}
.booking-cal__legend-red {
	background-color: color(red500);
}

.booking-cal__day .booking-cal__events {
	display: none;
}

.booking-cal__eventwrapper {
	margin-bottom: 5vw;
	overflow: hidden;
}

.booking-cal__eventitem {
	border-bottom: 1px solid color(coolgray200);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 2.5vw 0;

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		border-bottom: 0;
	}
}

.booking-cal__state,
.booking-cal__date {
	line-height: 40px;
}
.booking-cal__date {
	margin-right: 10px;
}

.booking-cal__requestwrapper {
	display: none;
	width: 100%;
}

.booking-cal__eventitem {
	&.active,
	&.always-active {
		.booking-cal__btn {
			display: none;
		}

		.booking-cal__requestwrapper {
			display: block;
		}
	}
}

// .booking-cal__request {
// 	max-width: 800px;
// }


// Backend styles
.booking__list {
	list-style-type: none;
	padding: 0;
}

.booking__date {
	margin-bottom: 2.5vw;
}
